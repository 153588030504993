<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">상세보기</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>
      <!--content-->
      <v-card-text>
        <div class="cont-group">
          <div class="top">
            <div class="txt-update" v-if="item.type=='1'">update : {{ item.modDateFormat }}</div>
            <div class="txt-group">
              <div class="txt">{{ item.memberName }} / {{ item.deptName }} / {{ item.posName }}</div>
              <div class="txt2">{{ item.regDateFormat }}</div>
            </div>
            <div class="btn-group" v-if="item.type=='1'">
              <div class="radio-group2 ty01">
                <v-radio-group v-model="item.status" @change="onChangeStatus" :disabled="item.memberIdx==user.memberIdx?false:true">
                  <v-radio label="진행중" value="2"></v-radio>
                  <v-radio label="보류" value="3"></v-radio>
                  <v-radio label="완료" value="4"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
          <ul class="list-group">
            <li v-if="item.type=='1'">
              <div class="tit">행동</div>
              <div class="txt ell01">{{ item.behaviorTitle }}</div>
            </li>
            <li v-if="item.type=='1'">
              <div class="tit">장소</div>
              <div class="txt ell01">{{ item.place }}</div>
            </li>
            <li>
              <div class="tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
              <div class="txt ell01">{{ item.situation }}</div>
            </li>
            <li v-if="item.type=='1'">
              <div class="tit">원인</div>
              <div class="txt ell01">{{ item.cause }}</div>
            </li>
            <li v-if="item.type=='1'">
              <div class="tit">시정조치내용</div>
              <div class="txt ell01">{{ item.action }}</div>
            </li>
            <li>
              <div class="tit">{{item.type=='1'?'시정조치대상자':'칭찬대상자'}}</div>
              <div class="txt ell01">{{ item.targetText }}</div>
            </li>
            <li v-if="item.actionYn=='Y'">
              <div class="tit">조치기한</div>
              <div class="txt ell01">{{ item.goalDate }}</div>
            </li>
            <li v-if="item.type=='1'">
              <div class="tit">진행상태</div>
              <div class="txt2 c01">{{ item.statusText }}</div>
            </li>
          </ul>
          <div class="img-group">
            <div class="item"
                 v-for="(item2,i2) in item.timelineImages"
                 :key="i2">
              <v-btn @click="onClickPreview(utils.getBaseUrl('timeline', item2.filePath))">
                <img :src="utils.getBaseUrl('timeline', item2.filePath)">
              </v-btn>
            </div>
          </div>
        </div>
        <InputComment :idx="item.idx" @confirmComment="confirmComment"/>
        <div class="comment-group">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-title>댓글({{item.timelineComments?item.timelineComments.length:'0'}}개)</v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="box"
                     v-for="(comment,i) in item.timelineComments"
                     :key="i">
                  <div class="txt-group">
                    <div class="txt">{{ comment.memberName }} / {{ comment.deptName }} / {{ comment.posName }}</div>
                    <div class="txt2">{{ comment.regDateFormat }}</div>
                  </div>
                  <div class="txt-group2">
                    <div class="txt">{{ comment.comment }}</div>
                    <div class="img-group" v-if="comment.filePath">
                      <div class="item">
                        <v-btn @click="onClickPreview(utils.getBaseUrl('comment', comment.filePath))">
                          <img :src="utils.getBaseUrl('comment', comment.filePath)">
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class=" d-flex justify-end pop-bottom-bg">
        <c-btn01 :btnText="'확인'" />
      </v-card-actions>
    </v-card>
    <DialogImgView v-model="previewDialogModel" @close="previewDialogModel=false" :src="previewSrc"/>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const previewDialogModel = ref(false)
const item:any = ref({})
const previewSrc = ref('')
const user:any = ref('')

//watch
watch(dialog, (newObj) => {
  if(dialog.value)
    onClickSearch()
})

//hook
onMounted(() => {
  user.value = JSON.parse(localStorage.getItem('user'))
  if(import.meta.env.MODE == 'development'){
    item.value=[]
  }
})

//function
function onClickSearch(){
  const popupIdx = storeRef.popupIdx.value
  let params = {
    idx:popupIdx
  }

  TimelineService.getTimelineList(params).then(
    (response) => {
      //타임라인
      if(response.result.length > 0)
        item.value = response.result[0]
      item.value.typeText = item.value.type=='1'?'교정적 안전대화':'지지적 안전대화'
      item.value.actionYnText = item.value.actionYn=='Y'?'필요':'불필요'
      item.value.targetText = '없음'
      if(item.value.targetType == '2') item.value.target = item.value.targetDept
      else if(item.value.targetType == '3') item.value.target = item.value.targetMember
      item.value.goalDate = dayjs(item.value.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(item.value.goalDate).format('YYYY-MM-DD')
      item.value.statusText = '준비'
      if(item.value.status == '2')
        item.value.statusText = '진행중'
      else if(item.value.status == '3')
        item.value.statusText = '보류'
      else if(item.value.status == '4')
        item.value.statusText = '완료'
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickPreview(item){
  previewSrc.value = item
  previewDialogModel.value = true
}

function onChangeStatus(){
  const popupIdx = storeRef.popupIdx.value
  let params = {
    idx:popupIdx,
    status:item.value.status
  }

  TimelineService.updateTimeline(params).then(
    (response) => {
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function confirmComment(params){
  item.value.timelineComments.unshift(params)
}

function onClickDeleteComment(comment){
  let params = {
    idx:comment.idx
  }

  TimelineService.deleteTimelineComment(params).then(
    (response) => {
      item.value.timelineComments = _.pull(item.value.timelineComments, comment)
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>


<style lang="scss">
</style>
