<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="57rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">대상추가</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text>
          <div class="tab-group ty01">
            <v-tabs
              v-model="tab"
            >
              <v-tab value="tabCont1">부서</v-tab>
              <v-tab value="tabCont2">개인</v-tab>
            </v-tabs>
          </div>
          <div class="content-box bg-none">
            <v-tabs-window v-model="tab">
              <v-tabs-window-item value="tabCont1">
                <div class="selected-group ty02 scrollbar">
                  <v-item-group selected-class="bg-active ">
                    <v-container>
                      <div class="list-group">
                        <div class="list-row" v-for="(item, i) in depts" :key="i">
                          <div class="list-txt">
                            <v-item v-slot="{ selectedClass, toggle }">
                              <v-card
                                :class="item.selected?'bg-active':''"
                                dark
                                @click="onClickRow('dept',i)"
                              >
                                <div class="txt">
                                  {{ item.deptName }}
                                </div>
                              </v-card>
                            </v-item>
                          </div>
                        </div>
                      </div>
                    </v-container>
                  </v-item-group>
                </div>
              </v-tabs-window-item>
              <v-tabs-window-item value="tabCont2">
                <div class="form-group col-ty">
                  <!--form-row-->
                  <div class="form-row">
                    <div class="row">
                      <div class="col">
                        <c-Input v-model="memberId" v-bind="InputName" class="size02" @keyup.enter="getData"/>
                      </div>
                      <div class="col">
                        <Select v-model="posIdx" v-bind="selectPos"/>
                      </div>
                      <div class="col">
                        <Select v-model="deptIdx" v-bind="selectDept"/>
                      </div>
                      <div class="col">
                        <Select v-model="shiftYn" v-bind="selectShift"/>
                      </div>
                      <div class="col">
                        <Select v-model="targetYn" v-bind="selectTarget"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="selected-group scrollbar">
                  <v-item-group selected-class="bg-active ">
                    <v-container>
                      <div class="list-group">
                        <div class="list-row"
                             v-for="(item, i) in members"
                             :key="i"
                        >
                          <div class="list-txt">
                            <v-item v-slot="{ selectedClass, toggle }">
                              <v-card
                                :class="item.selected?'bg-active':''"
                                dark
                                @click="onClickRow('member',i)"
                              >
                                <div class="txt">
                                  {{item.memberInfo}}
                                </div>
                              </v-card>
                            </v-item>
                          </div>
                        </div>
                      </div>
                    </v-container>
                  </v-item-group>
                </div>
              </v-tabs-window-item>
            </v-tabs-window>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 v-bind="btnCancel" @click="dialog=false"/>
          <c-btn02 v-bind="btnSave" @click="onClickConfirm"/>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const tab = ref(null)
const memberId = ref('')
const posIdx = ref({name: '직책', value: ''})
const deptIdx = ref({name: '부서', value: ''})
const shiftYn = ref({name: '교대조', value: ''})
const targetYn = ref({name: '대상', value: ''})
const headYn = ref({name: '조직장', value: ''})
const members = ref([])
const depts = ref([])
const selectDept = ref({items: [{name: '부서', value: ''}]})
const selectPos = ref({items: [{name: '직책', value: ''}]})

//ui
const btnSave = {
  btnText: '저장',
}
const btnCancel = {
  btnText: '취소',
}
const InputName = {
  type: 'text',
  placeholder: '이름',
}
const selectTarget = {items: [{name: '대상', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectHead = {items: [{name: '조직장', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectShift = {items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}

//hook
onBeforeUpdate (()=>{
  members.value = []
  depts.value = []
})

onUpdated(() => {
  if(!storeRef.popupIdx.value)
    return;

  if(import.meta.env.MODE == 'development'){
    members.value = [
      {
        memberInfo: '현장소장 / 염소정제팀 / 관리감독자',
        idx: '1',
        selected:true
      },
      {
        memberInfo: '현장소장2 / 염소정제팀2 / 관리감독자2',
        idx: '2',
        selected:false
      },
    ]
    depts.value = [
      {
        deptName:'부서1',
        idx: '1',
        selected:false
      },
      {
        deptName:'부서2',
        idx: '2',
        selected:true
      },
    ]
  }
  else {
    if(dialog.value)
      getCompanyInfo()
  }
})

//watch
const filterArray = computed(()=>(param) => {return _.filter(depts.value, {parentIdx: param}) })
watch(posIdx, (newValue) => {
  getData()
})
watch(deptIdx, (newValue) => {
  getData()
})
watch(shiftYn, (newValue) => {
  getData()
})
watch(targetYn, (newValue) => {
  getData()
})
watch(headYn, (newValue) => {
  getData()
})

//function
function getCompanyInfo(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    idx: companyIdx,
  }

  CompanyService.getCompany(params).then(
    (response) => {
      if(response.result){
        let posArr = {items: [{name: '부서', value: ''}]}
        response.result.posList.forEach(function (item){
          posArr.items.push({name:item.treeName, value:item.idx})
        })
        selectPos.value = posArr

        let deptArr = {items: [{name: '직책', value: ''}]}
        response.result.deptList.forEach(function (item){
          deptArr.items.push({name:item.treeName, value:item.idx})
        })
        selectDept.value = deptArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )

  let params2 = {idx: 0, companyIdx: companyIdx, treeType: 'dept'}
  CompanyService.getCompanyTreeList(params2).then(
    (response) => {
      let deptArr = []
      _.forEach(response.result, function (item){
        if(storeRef.popupType.value == '2' && storeRef.popupIdx.value == item.idx)  //2부서 3개인
          deptArr.push({idx:item.idx, deptName:item.treeName, selected:true, targetType:'2'})
        else
          deptArr.push({idx:item.idx, deptName:item.treeName, selected:false, targetType:'2'})
      })
      depts.value = deptArr
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function getData(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
    memberId: memberId.value,
    posIdx: posIdx.value['value'],
    deptIdx: deptIdx.value['value'],
    shiftYn: shiftYn.value['value'],
    targetYn: targetYn.value['value'],
    headYn: headYn.value['value'],
  }

  MemberService.getMemberListFilter(params).then(
    (response) => {
      if(response.result){
        let memberArr = []
        _.forEach(response.result, function (item){
          if(storeRef.popupType.value == '3' && storeRef.popupIdx.value == item.idx)   //2부서 3개인
            memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName, selected:true, targetType:'3'})
          else
            memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName, selected:false, targetType:'3'})
        })
        members.value = memberArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickRow(type, i){
  let tempArr = depts.value
  if(type == 'member')
    tempArr = members.value

  const selected = tempArr[i].selected
  _.forEach(tempArr, function (val, key){
    if(key == i)
      val.selected = true
    else
      val.selected = false
  })
}

function onClickConfirm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else {
    dialog.value = false
    if(tab.value == 'tabCont1')
      emit('confirm', _.filter(depts.value, {selected: true}))
    else
    if(tab.value == 'tabCont2')
      emit('confirm', _.filter(members.value, {selected: true}))
  }
}
</script>


<style lang="scss">
</style>
