<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="utils.movePage('/actionManagement')"/>
      </div>
      <NaviBox/>
    </div>
    <div class="page3">
      <div class="tab-group ty02">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">모니터링</v-tab>
          <v-tab value="tabCont2">보고서</v-tab>
          <v-tab value="tabCont3">활동이력</v-tab>
        </v-tabs>
        <c-btnText01 :btnText="'QR코드 생성'" class="ty04" @click="onClickQR"/>
      </div>
      <div class="content-box bg-none tab-cont-wrap">
        <div class="tit-group02 mo-ty01">
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <monitoringTabCont1/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <monitoringTabCont2/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont3">
            <monitoringTabCont3/>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
  <!--popup: qrcord 생성-->
  <DialogQrcord v-model="DialogQRModel"
                :pop-title="'QR코드'"
                @close="DialogQRModel=false"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from "@/store/common";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()
store.moveTab('tabCont1')

//bind
const DialogQRModel = ref(false)
//모니터링 메뉴 열고 닫기
const amPage = ref('naviOff')
const tabIdx = route.params.tab?route.params.tab:''
const viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value

//ui
const tab = storeRef.tabName

//hook
onMounted(() => {
  tab.value = 'tabCont'+tabIdx
})

// function
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}

function onClickTab(type){
  store.moveTab('tabCont'+type)
}

function onClickQR(){
  let params = {
    idx: viewIdx
  }
  BbsService.getBbs(params).then(
    (response) => {
      DialogQRModel.value = true
      storeRef.popupIdx.value = viewIdx
      storeRef.popupTitle.value = response.result.monitoringTitle
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>

<style lang="scss" scoped>
</style>
