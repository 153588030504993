<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card :id="'print-div'">
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="qr-group">
          <p>{{companyName}}<br>{{monitoringTitle}}</p>
          <img :src="qrcode" alt="">
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end"><!--pop-bottom-bg-->
        <c-btn02 :btnText="'파일저장'" @click="onClickPrint('f')"/>
        <c-btn01 :btnText="'인쇄'" @click="onClickPrint('p')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import {toDataURL} from 'qrcode';
import html2canvas from 'html2canvas';
import printJS from 'print-js';

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//define
const props = defineProps(['popTitle'])
const emit = defineEmits(['close'])
const dialog = defineModel()

//bind
const qrcode = ref<string>()
const monitoringTitle = ref('')
const companyName = ref(localStorage.getItem('companyName'))
monitoringTitle.value = storeRef.popupTitle.value

const generateQR = async (url: string) => {
  qrcode.value = await toDataURL(url)
}

onMounted(async () => {
  const url = 'https://impactus2.co.kr/monitoring/1/'+storeRef.popupIdx.value
  await generateQR(url)
})

//function
function onClickPrint(type){
  if(type == 'f'){
    handleOnCoverCanvas()
  }
  else if(type == 'p'){
    printJS({
      printable: 'print-div',
      type: 'html',
      targetStyles: ['*'],
      maxWidth: '100%',
    } as any);
  }
}

const handleOnCoverCanvas = async () => {
  const paper = document.querySelector("#print-div");

  if (paper !== null && paper instanceof HTMLElement) {
    const canvas = await html2canvas(paper);
    const imageFile = canvas.toDataURL("image/png", 1.5);

    let a = document.createElement('a');
    a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
    a.download = companyName+'/'+monitoringTitle+'.jpeg';
    a.click()
    //return imageFile;
  }
};
</script>


<style lang="scss">

</style>
