<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">보류사유</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <Textarea :placeholder="'보류사유를 입력하세요.'" v-model="delayReason"/>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn02 :btnText="'취소'" @click="dialog=false"/>
        <c-btn01 :btnText="'저장'" @click="onClickConfirm"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const props = defineProps(['popTitle'])
const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//bind
const delayReason = ref('')

//hook
onUpdated(() => {
  if(dialog.value)
    delayReason.value = ''
})

//function
function onClickConfirm(){
  emit('confirm', delayReason.value)
}
</script>


<style lang="scss">

</style>
