<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="90rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">참여 대상</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text class="target-pop-group">
          <div class="dv-ty01">
            <div class="inner scrollbar">
              <div class="form-group col-ty">
                <div class="text-title">대상추가</div>
                <!--form-row-->
                <div class="form-row">
                  <div class="row">
                    <div class="col">
                      <c-Input v-model="memberId" v-bind="InputName" class="size02" @keyup.enter="getData"/>
                    </div>
                    <div class="col">
                      <Select v-model="posIdx" v-bind="selectPos"/>
                    </div>
                    <div class="col">
                      <Select v-model="deptIdx" v-bind="selectDept"/>
                    </div>
                    <div class="col">
                      <Select v-model="shiftYn" v-bind="selectShift"/>
                    </div>
                    <div class="col">
                      <Select v-model="targetYn" v-bind="selectTarget"/>
                    </div>
                    <div class="col">
                      <Select v-model="headYn" v-bind="selectHead"/>
                    </div>
                  </div>
                </div>
                <div class="text-total">{{memberCnt}}명 선택</div>
              </div>
              <div class="selected-group2">
                <v-item-group selected-class="bg-active" multiple>
                  <v-container>
                    <div class="list-group">
                      <div class="list-row"
                           v-for="(item, i) in members"
                           :key="i"
                      >
                        <div class="list-txt">
                          <v-item>
                            <v-card
                              :class="item.selected?'bg-active':''"
                              dark
                              @click="onClickMemberRow(i)"
                            >
                              <div class="txt">
                                {{item.memberInfo}}
                              </div>
                            </v-card>
                          </v-item>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-item-group>
              </div>
            </div>
            <div class="bottom-group">
              <c-btnLine01 :btnText="'선택추가'" class="sm" @click="onClickAdd('s')"/>
              <c-btnLine01 :btnText="'전체추가'" class="sm" @click="onClickAdd('a')"/>
            </div>
          </div>
          <div class="dv-ty02">
            <div class="inner scrollbar">
              <div class="form-group col-ty">
                <div class="text-title">대상</div>
                <div class="text-total">{{targetCnt}}명 선택</div>
              </div>
              <div class="selected-group2">
                <v-item-group selected-class="bg-active" multiple>
                  <v-container>
                    <div class="list-group">
                      <div class="list-row"
                           v-for="(item, i) in targets"
                           :key="i"
                      >
                        <div class="list-txt">
                          <v-item v-slot="{ selectedClass, toggle }">
                            <v-card
                              :class="item.selected?'bg-active':''"
                              dark
                              @click="onClickTargetRow(i)"
                            >
                              <div class="txt">
                                {{item.memberInfo}}
                              </div>
                              <div class="txt2">
                                {{item.monitoringInfo}}
                              </div>
                            </v-card>
                          </v-item>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-item-group>
              </div>
              <div class="bottom-group">
                <c-btn02 :btnText="'선택제거'" class="sm" @click="onClickRemove('s')"/>
                <c-btn02 :btnText="'전체제거'" class="sm" @click="onClickRemove('a')"/>
              </div>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 v-bind="btnSave" @click="handleForm"/>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import {ref} from 'vue'
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const memberId = ref('')
const posIdx = ref({name: '직책', value: ''})
const deptIdx = ref({name: '부서', value: ''})
const shiftYn = ref({name: '교대조', value: ''})
const targetYn = ref({name: '대상', value: ''})
const headYn = ref({name: '조직장', value: ''})
const memberCnt = ref(0)
const members = ref([])
const targetCnt = ref(0)
const targets = ref([])
const selectDept = ref({items: [{name: '부서', value: ''}]})
const selectPos = ref({items: [{name: '직책', value: ''}]})

//ui
const btnSave = {
  btnText: '저장',
}
const InputName = {
  type: 'text',
  placeholder: '이름',
}
const selectTarget = {items: [{name: '대상', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectHead = {items: [{name: '조직장', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectShift = {items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}

//hook
onBeforeUpdate (()=>{
  members.value = []
  targets.value = []
})

onUpdated(() => {
  if(!storeRef.popupIdx.value)
    return;

  if(import.meta.env.MODE == 'development'){
    members.value = [
      {
        memberInfo: '현장소장 / 염소정제팀 / 관리감독자',
        idx: '1',
        selected:false
      },
      {
        memberInfo: '현장소장2 / 염소정제팀2 / 관리감독자2',
        idx: '2',
        selected:false
      },
    ]
    targets.value = [
      {
        monitoringInfo:'모니터링B 외 2',
        memberInfo: '현장소장 / 염소정제팀 / 관리감독자',
        idx: '1',
        selected:false
      },
      {
        monitoringInfo:'모니터링B 외 1',
        memberInfo: '현장소장2 / 염소정제팀2 / 관리감독자2',
        idx: '2',
        selected:false
      },
    ]
  }
  else {
    if(dialog.value)
      getCompanyInfo()
  }
})

//watch
watch(posIdx, (newValue) => {
  getData()
})
watch(deptIdx, (newValue) => {
  getData()
})
watch(shiftYn, (newValue) => {
  getData()
})
watch(targetYn, (newValue) => {
  getData()
})
watch(headYn, (newValue) => {
  getData()
})
watch(members, (newValue) => {
  memberCnt.value = _.filter(members.value, {selected: true}).length
}, {deep: true})
watch(targets, (newValue) => {
  targetCnt.value = _.filter(targets.value, {selected: true}).length
}, {deep: true})

//function
function getCompanyInfo(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    idx: companyIdx,
  }

  CompanyService.getCompany(params).then(
    (response) => {
      if(response.result){
        let posArr = {items: [{name: '부서', value: ''}]}
        response.result.posList.forEach(function (item){
          posArr.items.push({name:item.treeName, value:item.idx})
        })
        selectPos.value = posArr

        let deptArr = {items: [{name: '직책', value: ''}]}
        response.result.deptList.forEach(function (item){
          deptArr.items.push({name:item.treeName, value:item.idx})
        })
        selectDept.value = deptArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )

  let popupType = storeRef.popupType.value
  let popupIdx = storeRef.popupIdx.value
  let popupItems = storeRef.popupItems.value
  let targetArr = []
  //다중 처리일경우
  if(popupType == 'all') {
    _.forEach(popupItems, function(val, key) {
      targetArr.push(val.idx)
    })
  }
  else {
    if(!utils.isEmpty(popupIdx))
      targetArr.push(popupIdx)
  }

  //모니터링 대상이 없으면 불러오지 않는다
  if(targetArr.length == 0)
    return

  let params2 = {
    companyIdx: companyIdx,
    targetType: popupType, //1:참여자 2:대상자 3:audit, all:다중선택
    targetArr: targetArr
  }

  MemberService.getMemberListFilter(params2).then(
    (response) => {
      if(response.result){
        let memberArr = []
        response.result.forEach(function (item){
          if(popupType != 'all')
            memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName, selected:false})
          else
            memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName+' ('+item.monitoringTitle+'외'+(item.monitoringCnt-1)+')', selected:false})
        })
        targets.value = memberArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function getData(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
    memberId: memberId.value,
    posIdx: posIdx.value['value'],
    deptIdx: deptIdx.value['value'],
    shiftYn: shiftYn.value['value'],
    targetYn: targetYn.value['value'],
    headYn: headYn.value['value'],
  }

  MemberService.getMemberListFilter(params).then(
    (response) => {
      if(response.result){
        let memberArr = []
        response.result.forEach(function (item){
          memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName, selected:false})
        })
        members.value = memberArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    emit('confirm', targets.value)
  }
}

function onClickMemberRow(i){
  const selected = members.value[i].selected
  if(selected)
    members.value[i].selected = false
  else
    members.value[i].selected = true
}

function onClickTargetRow(i){
  const selected = targets.value[i].selected
  if(selected)
    targets.value[i].selected = false
  else
    targets.value[i].selected = true
}

function onClickAdd(type){
  let selectedArr = null
  if(type == 's')
    selectedArr = _.filter(members.value, {selected: true})
  else if(type == 'a')
    selectedArr = members.value

  _.forEach(selectedArr, function(val, key) {
    if(!_.find(targets.value, {idx:val.idx})) {
      let newRow = {idx:val.idx, memberInfo:val.memberInfo, selected:false}
      targets.value.push(newRow)
    }
  })
}

function onClickRemove(type){
  if(type == 's')
    targets.value = _.filter(targets.value, {selected: false})
  else if(type == 'a')
    targets.value = []
}

</script>


<style lang="scss">

</style>
