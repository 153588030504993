<template>
  <div class="textarea-group">
    <v-textarea
      row-height="25"
      rows="3"
      auto-grow
      shaped
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      v-model="model"
    ></v-textarea>
  </div>
</template>
<style lang="scss">
.textarea-group{
  .v-form{
    display:flex;
  }
  //폰트 크기
  .v-field{font-size:1.6rem;border-radius:.4rem}
  //기본 배경색
  .v-field--variant-filled .v-field__overlay{
    opacity:0
  }
  //포커스 배경색
  .v-field--variant-filled.v-field--focused .v-field__overlay{
    opacity:0.1;
  }
  .v-field__input{
    min-height:5rem;
    padding-top:1.3rem;
  }
  .v-input--density-default{
    --v-input-control-height:5rem;
    --v-input-padding-top:1.3rem
  }
  .v-input--density-default .v-field--variant-solo,
  .v-input--density-default .v-field--variant-solo-inverted,
  .v-input--density-default .v-field--variant-solo-filled,
  .v-input--density-default .v-field--variant-filled{
    --v-input-control-height:5rem;
  }
  .v-input--density-default .v-field--variant-outlined,
  .v-input--density-default .v-field--single-line,
  .v-input--density-default .v-field--no-label{
    background:var(--color-white);
    --v-field-padding-bottom: 1.3rem;
  }
  //기본 라인
  .v-field--variant-filled .v-field__outline::before{
    border:1px solid var(--input-line);
    opacity: 0.38;
    border-radius:.4rem;

  }
  //포커스 라인
  .v-field--variant-filled .v-field__outline::after{
    border:1px solid var(--input-line);
    transition:none;
    border-radius:.4rem;
  }
  //에러케이스
  .v-field--error:not(.v-field--disabled) .v-field__outline:before{
    border-color: rgb(var(--v-theme-error));
    opacity:1;
  }
  .v-text-field .v-input__details{
    display:none;
  }
  .v-input--error .v-input__details{
    display:flex;
  }

  .v-field--disabled{
    opacity:1;
    background:var(--input-disabled-bg) !important;
  }
  .v-input--readonly .v-field{
    opacity:1;
    //background:var(--input-disabled-bg) !important;
  }
}
</style>
<script lang="ts" setup>
import { ref } from 'vue'
import { Field } from 'vee-validate';
const props = defineProps(['placeholder', 'type' ,'readonly', 'disabled' ]);
//const emits = defineEmits(['update:modelValue'])
const model = defineModel()
//const textareaValue = ref('')
//textareaValue.value = props.modelValue
</script>
