<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">{{monitoringInfo.monitoringTitle}}</h4>
      </div>
      <div class="tab-group ty01">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">현재 모니터링</v-tab>
          <v-tab value="tabCont2">전체 모니터링</v-tab>
          <v-tab value="tabCont3">부서 모니터링</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <monitoring-tab-cont3-table :monitoringType="1"/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <monitoring-tab-cont3-table :monitoringType="2"/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont3">
            <monitoring-tab-cont3-table :monitoringType="3"/>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";

//store
const route:any = useRoute()

//bind
const tab = ref('tabCont1')
const monitoringInfo:any = ref({})
let viewIdx = route.params.idx

//hook
onMounted(()=>{
  if(import.meta.env.MODE == 'development'){
  }
  else {
    if (viewIdx) {
      //모니터링정보
      BbsService.getBbsMonitoring({idx: viewIdx}).then(
        (response) => {
          //모니터링
          monitoringInfo.value = response.result
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

</script>


<style lang="scss" scoped>
.container.main.on{
  .v-window{}
}
</style>

