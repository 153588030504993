<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <v-card-text class="ty01" @click.stop="">
          <div class="tab-group ty01 ty01-01">
            <v-tabs
              v-model="tab"
            >
              <v-tab value="tabCont1">모니터링</v-tab>
              <v-tab value="tabCont2" >안전대화</v-tab>
            </v-tabs>
          </div>
          <div class="content-box bg-none">
            <div class="content-inner-box">
            <v-tabs-window v-model="tab">
              <v-tabs-window-item value="tabCont1">
                <div class="list-group2 ty01 ty01-01" @click.stop.prevent="onClickStop">
                  <!--list-->
                  <div class="list" v-for="(item, i) in monitoringItems" :key="i">
                    <div class="row1">
                      <div class="num">{{i+1}}</div>
                      <div class="txt">{{item.behaviorTitle}}</div>
                      <div class="right-area">
                        <div class="check-group ty01">
                          <v-checkbox label="해당없음"
                                      :model-value="item.etcYn"
                                      @click.stop="item.etcYn=='Y'?'N':'Y'"
                                      true-value="Y" false-value="N"></v-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row2">
                      <div class="radio-group2">
                        <v-radio-group v-model="item.answerData">
                          <v-radio label="안전" value="1"></v-radio>
                          <v-radio label="불안전" value="0"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="inner" v-if="item.answerData == 0">
                        <div class="radio-group3">
                          <v-radio-group v-model="item.commentIdx">
                            <v-radio v-for="(item2, i ) in item.behaviorComments" :label="item2.commentTitle" :value="item2.idx"></v-radio>
                          </v-radio-group>
                        </div>
                        <c-input :placeholder="item.commentIdx==0?item.comment:''"></c-input>
                      </div>
                    </div>
                  </div>
                  <!--list-->
                </div>
              </v-tabs-window-item>
              <v-tabs-window-item value="tabCont2">
                <div class="dropdown-group2 ty01 mo-ty01">
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel v-for="(item, i) in timelineItems" :key="i">
                      <v-expansion-panel-title>안전대화{{i+1}}</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div class="form-group col-ty">
                          <div class="tit-group">
                            <div class="tit02">기본정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">타입</div>
                            <div class="row">
                              <c-Input v-model="item.typeText" :name="item.typeText" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row" v-if="item.type=='1'">
                            <div class="txt-tit">시정조치여부</div>
                            <div class="row">
                              <c-Input v-model="item.actionYnText" :name="item.actionYnText" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row" v-if="item.actionYn=='Y' || item.type=='2'">
                            <div class="txt-tit">대상자</div>
                            <div class="row">
                              <c-Input v-model="item.targetText" :name="item.targetText" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row" v-if="item.actionYn=='Y'">
                            <div class="txt-tit">조치기한</div>
                            <div class="row">
                              <c-Input v-model="item.goalDate" :name="item.goalDate" :readonly="readonly"/>
                            </div>
                          </div>

                        </div>
                        <div class="form-group col-ty line-ty">
                          <div class="tit-group">
                            <div class="tit02">상세정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row" v-if="item.type=='1'">
                            <div class="txt-tit">모니터링행동</div>
                            <div class="row">
                              <c-Input v-model="item.behaviorTitle" :name="item.behaviorTitle" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row" v-if="item.type=='1'">
                            <div class="txt-tit">장소</div>
                            <div class="row">
                              <c-Input v-model="item.place" :name="item.place" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07">
                            <div class="txt-tit">{{item.type=='1'?'구체적상황':'칭찬 사유'}}</div>
                            <div class="row">
                              <Textarea v-model="item.situation" :name="item.situation" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07" v-if="item.type=='1'">
                            <div class="txt-tit">원인</div>
                            <div class="row">
                              <Textarea v-model="item.cause" :name="item.cause" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07" v-if="item.type=='1'">
                            <div class="txt-tit">시정조치내용</div>
                            <div class="row">
                              <Textarea v-model="item.action" :name="item.action" :readonly="readonly"/>
                            </div>
                          </div>

                          <div class="img-slider02">
                            <v-carousel
                            >
                              <div class="img-wrap">
                              <v-carousel-item
                                v-for="(item2,i2) in item.timelineImages"
                                :key="i2"
                                :src="utils.getBaseUrl('timeline', item2.filePath)"
                                cover
                                @click="onClickPreview(item2)"
                              ></v-carousel-item>
                              </div>
                            </v-carousel>
                          </div>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-tabs-window-item>
            </v-tabs-window>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 v-bind="btnConfirm" @click.stop="dialog = false"/>
        </v-card-actions>
      </v-card>
      <DialogImgView v-model="previewDialogModel" @close="previewDialogModel=false" :src="previewSrc"/>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import dayjs from "dayjs";

const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const tab = ref(null)
const previewDialogModel = ref(false)
const panel = ref([0])
const monitoringInfo:any = ref({})
const monitoringItems:any = ref([])
const timelineItems:any = ref([{}])
const previewSrc = ref('')
/*
const typeModel = ref('')
const actionYnModel = ref('')
const targetTypeModel = ref('')
const goalDateModel = ref('')
const behaviorModel = ref('')
const placeModel = ref('')
const situationModel = ref('')
const causeModel = ref('')
const actionModel = ref('')
 */

const items:any = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]

//ui
const btnConfirm = {
  btnText: '확인',
}

//hook
onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    timelineItems.value=[{
      answerIdx: '',
      type: '1',
      actionYn: 'N',
      targetType: '1',
      behaviorIdx:{name:'미선택'},
      place:{name:'미선택'}
    }]
    monitoringItems.value = [
      {
        "idx": 1,
        "behaviorTitle": "1",
        "behaviorContent": "2",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "#tag,#tag2",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 20:29:52.156732",
        'answerData': '1',
        "duplicateYn": 'Y'
      },
      {
        "idx": 2,
        "behaviorTitle": "2",
        "behaviorContent": "22",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "333",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 21:04:48.400642",
        'answerData': '1',
        "duplicateYn": 'Y'
      }
    ]
    monitoringInfo.value = {
      "idx": 6,
      "companyIdx": 3,
      "monitoringTitle": "test2",
      "monitoringDiv": null,
      "startDate": "2024-12-12 00:00:00.0",
      "endDate": "2025-01-31 00:00:00.0",
      "periodType": "2",
      "periodStartDay": "1",
      "periodCnt": 2,
      "duplicateYn": "Y",
      "reportDay": "1",
      "reportAutoYn": "Y",
      "showOrder": 0,
      "showYn": "N",
      "activeYn": "Y",
      "delYn": "N",
      "modIdx": 34,
      "regIdx": 0,
      "modDate": "2024-12-25 00:49:25.046232",
      "regDate": "2024-12-08 22:44:31.078994",
      "targetCnt": 1,
      "auditTargetCnt": 0,
    }
  }
  else {
    const popupIdx = storeRef.popupIdx.value
    const viewIdx = storeRef.viewIdx.value
    if(viewIdx && dialog.value) {
      const user = JSON.parse(localStorage.getItem('user'))
      let params = {
        answerIdx: popupIdx,
        idx: viewIdx,
        isTemp: 'N'
      }

      BbsService.getBbsMonitoring(params).then(
        (response) => {
          //모니터링
          monitoringInfo.value = response.result

          //행동
          monitoringItems.value = response.result.monitoringBehaviors

          //코멘트 답변셋팅
          const answerItems = response.result.monitoringAnswers[0].answers
          _.forEach(monitoringItems.value, function (val, key){
            const answer = _.find(answerItems, {behaviorIdx:val.idx})
            if(answer) {
              val.answerData = answer.answerData
              val.answerData2 = answer.answerData2
              val.comment = answer.comment
              val.commentIdx = answer.commentIdx
            }
          })

          //임시저장
          _.forEach(monitoringItems.value, function(val, key) {
            _.forEach(monitoringInfo.value.monitoringAnswers, function(val2, key2) {
              _.forEach(val2.answers, function (val3, key3) {
                if (val3.behaviorIdx == val.idx) {
                  val.answerData = val3.answerData
                }
              })
            })
          })

          //타임라인
          if(response.result.monitoringTimelines.length > 0){
            timelineItems.value = response.result.monitoringTimelines
            _.forEach(timelineItems.value, function(val, key) {
              val.typeText = val.type=='1'?'교정적 안전대화':'지지적 안전대화'
              val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
              val.targetText = '없음'
              if(val.targetType == '2') val.target = val.targetDept
              else if(val.targetType == '3') val.target = val.targetMember
              val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
            })
          }
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

function onClickPreview(item){
  previewSrc.value = utils.getBaseUrl('timeline', item.filePath)
  previewDialogModel.value=true
}

function onClickStop(){
  utils.log('onClickStop')
}
</script>


<style lang="scss">
</style>
