<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">
          {{ popTitle }}
          <span class="pop-title02">{{ popSubTitle }}</span>
        </div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="dropdown-group ty01">
          <div class="form-group col-ty">
            <div class="form-row02"
                 v-for="(item, i) in items"
                 :key="i">
              <c-Input :placeholder="'장소입력'" class="size03" v-model="item.place" :name="'place'"/>
              <c-btn02 :btnText="'삭제'" class="" @click.stop="deleteRow(i)"/>
              <c-btnLine01 :btnText="'추가'" class="" v-if="i==0" @click.stop="addRow()"/>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave" @click="handleForm"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import BbsService from "@/services/bbs.service";
import CompanyService from "@/services/company.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";

const props = defineProps(['popTitle', 'popSubTitle'])
const emit = defineEmits(['close'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//ui
const btnSave = {
  btnText: '확인',
}

//bind
const items = ref([])

//hook
onBeforeUpdate (()=>{
  items.value = []
})

onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        place: '장소1',
        idx: '1',
      },
      {
        place: '장소2',
        idx: '2',
      }
    ]
  }
  else{
    if(dialog.value)
      getData()
  }
})

//function
function getData(){
  let companyIdx = localStorage.getItem('companyIdx')
  //let monitoringIdx = storeRef.popupIdx.value
  let params = {
    companyIdx: companyIdx,
    //monitoringIdx: monitoringIdx
  }

  BbsService.getMonitoringPlaceList(params).then(
    (response) => {
      items.value = response.result
      if(response.result.length == 0)
        addRow()
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function addRow(){
  let row = {
    place: '',
    idx: '',
  }
  items.value.push(row)
}

function deleteRow(index){
  items.value.splice(index, 1)
}

function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    let params = {
      companyIdx: companyIdx,
      monitoringPlaces: items.value
    }

    BbsService.insertMonitoringPlace(params).then(
      (response) => {
        emit('close', 'refresh')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
</script>


<style lang="scss">

</style>
