<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="tab-group ty01">
          <v-tabs
            v-model="tab"
          >
            <v-tab value="tabCont1">라이브러리({{ libraryCnt }})</v-tab>
            <v-tab value="tabCont2" >모니터링({{ monitoringCnt }})</v-tab>
          </v-tabs>
        </div>
        <div class="content-box bg-none">
          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="tabCont1">
              <c-Input :placeholder="'행동 혹은 태그를 입력하세요.'" v-model="searchModel" @search-word="onClickSearch" @keyup.enter="onClickSearch"/>
              <div class="dropdown-group ty01">
                <v-list v-model:opened="open">
                  <!--depth1 -->
                  <v-list-group v-for="(itemD1, seqD1) in filterArray(1)" :key="itemD1.idx">
                    <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" :title="itemD1.behaviorTitle" :class="itemD1.selected?'on':''">
                        <div class="check-group">
                          <v-checkbox
                            :model-value="itemD1.selected"
                            @click.stop="itemD1.selected = !itemD1.selected"
                          ></v-checkbox>
                        </div>
                      </v-list-item>
                    </template>
                    <!--depth2 -->
                    <v-list-group v-for="(itemD2, seqD2) in filterSortArray(itemD1.sortType)">
                      <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" :title="itemD2.behaviorTitle" :class="itemD2.selected?'on':''">
                          <div class="check-group">
                            <v-checkbox
                              :model-value="itemD2.selected"
                              @click.stop="itemD2.selected = !itemD2.selected"
                            ></v-checkbox>
                          </div>
                        </v-list-item>
                      </template>
                    </v-list-group>
                    <!--depth2 -->
                  </v-list-group>
                  <!--depth1 -->
                </v-list>
              </div>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont2">
              <c-Input :placeholder="'행동 혹은 태그를 입력하세요.'"/>
              <div class="dropdown-group ty01">
                <v-list v-model:opened="open">
                  <!--depth1 -->
                  <v-list-group v-for="(itemD1, seqD1) in filterArray2(1)" :key="itemD1.idx">
                    <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" :title="itemD1.behaviorTitle" :class="itemD1.selected?'on':''">
                        <div class="check-group">
                          <v-checkbox
                            :model-value="itemD1.selected"
                            @click.stop="itemD1.selected = !itemD1.selected"
                          ></v-checkbox>
                        </div>
                      </v-list-item>
                    </template>
                    <!--depth2 -->
                    <v-list-group v-for="(itemD2, seqD2) in filterSortArray2(itemD1.sortType)">
                      <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" :title="itemD2.behaviorTitle" :class="itemD2.selected?'on':''">
                          <div class="check-group">
                            <v-checkbox
                              :model-value="itemD2.selected"
                              @click.stop="itemD2.selected = !itemD2.selected"
                            ></v-checkbox>
                          </div>
                        </v-list-item>
                      </template>
                    </v-list-group>
                    <!--depth2 -->
                  </v-list-group>
                  <!--depth1 -->
                </v-list>
              </div>
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave" @click.prevent.stop="handleForm"/>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="3000" > {{ snackText }} </v-snackbar>
  </v-dialog>

</template>

<script setup lang="ts">
import {ref, computed, watch} from 'vue'
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import { storeToRefs } from 'pinia'
import { useCommonStore } from "@/store/common";
import _ from 'lodash';
import CompanyService from "@/services/company.service";

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const tab = ref(null)
const open = ref(['open'])
const items = ref([])
const items2 = ref([])
const libraryCnt = ref(0)
const monitoringCnt = ref(0)
const searchModel = ref(null)
let selectedArr = []
let selectedArr2 = []
const snackbar = ref(false)
const snackText = ref('')
const popTitle = ref('')

//ui
const btnSave = {
  btnText: '저장',
}

//hook
onBeforeUpdate (()=>{
  items.value = []
  items2.value = []
  tab.value = 'tabCont1'
})

onUpdated (() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        monitoringIdx: 1,
        monitoringTitle: '모니터링1',
        behaviorTitle: '행동1',
        sortType: 1,
        selected: false,
        treeLevel: 2
      },
      {
        idx: 2,
        monitoringIdx: 2,
        monitoringTitle: '모니터링2',
        behaviorTitle: '행동2',
        sortType: 1,
        selected: false,
        treeLevel: 2
      },
      {
        idx: 3,
        monitoringIdx: 3,
        monitoringTitle: '모니터링3',
        behaviorTitle: '행동3',
        sortType: 2,
        selected: false,
        treeLevel: 2
      },
    ]
  }
  else {
    if(dialog.value)
      onClickSearch()
  }
})

//watch
watch(items, (newValue:any) => {
  if(newValue.selected && _.find(selectedArr, {idx:newValue.idx})){
    snackText.value = '이미 추가된 행동입니다.'
    snackbar.value = true
    newValue.selected = false
  }
  else {
    selectedArr = _.filter(items.value, {selected: true, treeLevel: 2})
    libraryCnt.value = selectedArr.length
  }
}, {deep: true})

watch(items2, (newValue:any) => {
  if(newValue.selected && _.find(selectedArr2, {idx:newValue.idx})){
    snackText.value = '이미 추가된 행동입니다.'
    snackbar.value = true
    newValue.selected = false
  }
  else {
    selectedArr2 = _.filter(items2.value, {selected: true, treeLevel: 2})
    monitoringCnt.value = selectedArr2.length
  }
}, {deep: true})

watch(tab, (newValue) => {
  onClickSearch()
})

//computed
const filterArray = computed(()=>(param) => {return _.filter(items.value, {treeLevel: param}) })
const filterSortArray = computed(()=>(param) => {return _.filter(items.value, {sortType: param, treeLevel:2}) })
const filterMonitoringArray = computed(()=>(param) => {return _.filter(items.value, {monitoringIdx: param, treeLevel:2}) })
const filterArray2 = computed(()=>(param) => {return _.filter(items2.value, {treeLevel: param}) })
const filterSortArray2 = computed(()=>(param) => {return _.filter(items2.value, {sortType: param, treeLevel:2}) })
const filterMonitoringArray2 = computed(()=>(param) => {return _.filter(items2.value, {monitoringIdx: param, treeLevel:2}) })

//function
function onClickSearch(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
    keyword:searchModel.value
  }

  if(tab.value == 'tabCont1') {
    BbsService.getBehaviorList(params).then(
      (response) => {
        items.value = response.result
        items.value.push({behaviorTitle: '행동', sortType: '1', treeLevel: 1})
        items.value.push({behaviorTitle: '상태', sortType: '2', treeLevel: 1})

        const oriArr = storeRef.popupItems.value
        items.value.forEach(function (item, key) {
          if (_.find(oriArr, {idx: item.idx}))
            item.selected = true
        })
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
  else if(tab.value == 'tabCont2') {
    BbsService.getMonitoringFilter({}).then(
      (response) => {
        items2.value = []
        let bbsList = response.result.monitoringList
        _.forEach(bbsList, function (item){
          items2.value.push({behaviorTitle: item.monitoringTitle, sortType: item.idx, treeLevel: 1})
          _.forEach(item.behaviors, function (item2){
            items2.value.push({behaviorTitle: item2.behaviorTitle, sortType: item.idx, treeLevel: 2})
          })
        })

        const oriArr = storeRef.popupItems.value
        items2.value.forEach(function (item, key) {
          if (_.find(oriArr, {idx: item.idx}))
            item.selected = true
        })
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function handleForm() {
  if (import.meta.env.MODE == 'development') {
    emit('close', '')
  } else {
    let tab1 = _.filter(items.value, {selected: true, treeLevel:2})
    let tab2 = _.filter(items2.value, {selected: true, treeLevel:2})
    let resultItems = _.concat(tab1.value, tab2.value)
    resultItems = _.uniqBy(resultItems, 'idx')
    emit('confirm', _.filter(items.value, {selected: true}))
  }
}
</script>


<style lang="scss">

</style>
