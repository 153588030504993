<template>
  <div class="content-wrap sl-page-wrap" :class="slPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'안전리더십 대시보드'" @click="slListOpen"/>
      </div>
      <NaviBox02/>
    </div>
    <div class="page1">
      <div class="tit-group02">
        <h2 class="tit">Quick KPI</h2>
        <div class="navi-btn">
          <v-btn @click="slListOpen"/>
        </div>
      </div>
      <div class="card-group02">
        <div class="card-col ty02">
          <div class="icon ico8" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">90<span>점</span></div>
            <div class="txt2">안전리더십 점수</div>
          </div>
        </div>
        <div class="card-col ty04">
          <div class="icon ico9" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="inner">
              <div class="txt1">
                <span>전체</span>
                <div>20<span>개</span></div>
              </div>
              <div class="inner-line"></div>
              <div class="txt1">
                <span>완료</span>
                <div>13<span>개</span></div>
              </div>
              <div class="inner-line"></div>
              <div class="txt1">
                <span>완료율</span>
                <div>50<span>%</span></div>
                </div>
            </div>
            <div class="txt2">안전 리더십 과제현황</div>
          </div>
        </div>
      </div>
      <div class="page1-inner1">
        <div class="tit-group02">
          <h2 class="tit">안전리더십 일정</h2>
        </div>
        <div class="content-box">
          <div class="list-group3 scrollbar">
            <div class="box" v-for="n in 7" :key="n" >
              <div class="txt-date">2024.02.12</div>
              <ul>
                <li  v-for="n in 4" :key="n">
                  <v-btn  @click="utils.movePage('/safetyLeadershipRun')">
                    <span class="t1">안전 리더십 A 1회차</span>
                    <span class="t2">2024-03-11 ~ 2024.03.18</span>
                  </v-btn>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import utils from "@/common/Utils";

//모니터링 메뉴 열고 닫기
const slPage = ref('naviOff')
function slListOpen(){
  if(slPage.value == 'naviOff')
    slPage.value = 'naviOn'
  else
    slPage.value = 'naviOff'
}

</script>


<style lang="scss">
</style>
