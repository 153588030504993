<template>
  <div class="inp-comment">
    <div class="img-upload">
      <v-file-input
        label="File input w/ chips"
        chips
        multiple
        accept="image/png, image/jpeg"
        @change="onChangeImg"
      ></v-file-input>
    </div>
    <c-Input v-model="comment" :placeholder="'댓글 내용을 입력 후, 우측 게시 버튼을 눌러 주세요.'" @keyup.enter="onClickComment"/>
    <c-btn01 class="upload-ty" @click="onClickComment"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";

const props = defineProps(['btnText', 'idx'])
const emit = defineEmits(['confirmComment'])

//bind
let file = null
const comment = ref('')

//function
function onChangeImg(e: Event){
  file = (e.target as HTMLInputElement).files[0];
  /*
  if (file) {
    const tempObj = {idx:0, src:URL.createObjectURL(file)}
  }
   */
}

function onClickComment(){
  const idx = props.idx
  const formData = new FormData();
  if(file)
    formData.append("file", file);
  formData.append("timelineIdx", idx);
  formData.append("comment", comment.value);

  TimelineService.insertTimelineComment(formData).then(
    (response) => {
      if(response.success) {
        comment.value = ''
        file = null
        emit('confirmComment', response.result)
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>


<style lang="scss">
.inp-comment {
  position:sticky;
  top:-2rem;
  display:flex;
  background:#fff;
  z-index:2;
  .img-upload{
    .v-input{
      display:block;
      .v-input__prepend{
        margin:0;
        .v-icon{
          display:block;
          width:5rem;
          height:5rem;
          line-height:5rem;
          text-align:center;
          color:var(--color-white);
          background: var(--color-gray4) url("/src/assets/img/ico-img2.svg") center no-repeat;
          border-radius:.5rem;
          opacity:1;
          font-size:0;
        }
      }
      .v-field{
        .v-field__overlay,
        .v-field__loader,
        .v-label,
        .v-field__outline{
          display:none;
        }
        .v-chip{
          font-size:1.2rem
        }
      }
      .v-input__details{
        display:none;
      }
      .v-field__input{
        padding:.8rem 0;
        min-height:auto;
      }
      button.v-btn{
        display:block;
        width:5rem;
        height:5rem;
        line-height:5rem;
        text-align:center;
        color:var(--color-white);
        background: var(--color-gray4);
      }
    }
  }
  .input-txt-group{
    position:absolute;
    top:0;
    left:6rem;
    width:calc(100% - 12rem);
  }
  .btn01{
    position:absolute;
    top:0;
    right:0;
  }
}
</style>
