<template>
  <div class="tabCont page1">
    <div class="p-inner top">
      <div class="tit-group02">
        <h2 class="tit">Quick KPI</h2>
        <p class="txt">해당 값은 수신한 시정조치를 기준으로 표기됩니다.</p>
      </div>
      <div class="card-group02">
        <div class="card-col ty03">
          <div class="icon ico3" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ badCnt }}</div>
            <div class="txt2">전체 수신 시정조치</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico4" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ completeCnt }}</div>
            <div class="txt2">완료</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico5" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ ingCnt }}</div>
            <div class="txt2">진행중</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico6" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ delayCnt}}</div>
            <div class="txt2">보류</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico7" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">{{ goodCnt }}</div>
            <div class="txt2">내가 받은 지지적 피드백</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-inner">
      <div class="tit-group02">
        <h2 class="tit">수신 시정조치</h2>
        <c-btnLine01 :btnText="'더보기'" class="sm" @click="onClickView('receive')"/>
      </div>
      <div class="content-box">
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="receiveItems"
            hide-default-footer
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="utils.movePage('/myBoardDetail/'+item.idx)">
                <td>{{item.idx}}</td>
                <td>
                  <div class="ell01">
                    {{item.situation}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.goalDate)">
                    {{item.goalDate}}
                  </div>
                </td>
                <td>{{item.memberName}}</td>
                <td>{{item.targetText}}</td>

                <td>
                  <div :class="getColor2(item.status)">
                    {{item.statusText}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.status)">
                      {{item.statusText}}
                    </div>
                    <div class="ell01">
                      {{item.situation}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.goalDate)">
                      {{item.goalDate}}
                    </div>
                    &nbsp;/ {{item.memberName}} / {{item.targetText}}
                  </div>
                </td>
                <td>{{item.modDateFormat}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div class="p-inner">
      <div class="tit-group02">
        <h2 class="tit">발행 시정조치</h2>
        <c-btnLine01 :btnText="'더보기'" class="sm" @click="onClickView('send')"/>
      </div>
      <div class="content-box">
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="sendItems"
            hide-default-footer
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="utils.movePage('/myBoardDetail/'+item.idx)">
                <td>{{item.idx}}</td>
                <td>
                  <div class="ell01">
                    {{item.situation}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.goalDate)">
                    {{item.goalDate}}
                  </div>
                </td>
                <td>{{item.memberName}}</td>
                <td>{{item.targetText}}</td>

                <td>
                  <div :class="getColor2(item.status)">
                    {{item.statusText}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.status)">
                      {{item.statusText}}
                    </div>
                    <div class="ell01">
                      {{item.situation}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.goalDate)">
                      {{item.goalDate}}
                    </div>
                    &nbsp;/ {{item.memberName}} / {{item.targetText}}
                  </div>
                </td>
                <td>{{item.modDateFormat}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useCommonStore } from "@/store/common";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import dayjs from "dayjs";

//1:나의시정 2:부서시정 3:나의칭찬 4:부서칭찬
const props = defineProps(['tabType'])
//부모창에서 실행할 수 있는함수.. 아직 사용안함
defineExpose({onClickSearch});
//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const completeCnt = ref(0)
const ingCnt = ref(0)
const delayCnt = ref(0)
const badCnt = ref(0)
const goodCnt = ref(0)
const receiveItems:any = ref([])
const sendItems:any = ref([])

//ui
const headers = ref([
  { title: '순번', key: 'idx' },
  { title: '시정조치', key: 'situation' },
  { title: '조치기한', key: 'goalDate'},
  { title: '작성자 ', key: 'memberName' },
  { title: '대상자', key: 'targetText' },
  { title: '진행상황', key: 'statusText' },
  { title: '시정조치 / 조치기한 / 작성자 / 대상자', key: 'moDate' },
  { title: '등록일', key: 'modDateFormat' },
])

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    receiveItems.value = [
      {
        idx: '1',
        situation: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
        goalDate: '2024-01-25',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '진행중',
        modDateFormat: '2024-01-25'
      },
      {
        idx: '2',
        situation: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
        goalDate: '2024-01-23',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '보류',
        modDateFormat: '2024-01-25'
      },
    ]
    sendItems.value =[
      {
      idx: '3',
      situation: '시정조치 내용',
      goalDate: '2024-01-22',
      memberName: '김명기',
      targetText: '마케팅팀',
      statusText: '완료',
      modDateFormat: '2024-01-25'
    },
      {
        idx: '4',
        situation: '시정조치 내용',
        goalDate: '2024-01-22',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '완료',
        modDateFormat: '2024-01-25'
      },
      {
        idx: '5',
        situation: '시정조치 내용',
        goalDate: '2024-01-22',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '완료',
        modDateFormat: '2024-01-25'
      },
    ]
  }
  else {
    onClickSearch()
  }
})

onUpdated(() => {
  console.log('updated')
})

//function
function onClickSearch(){
  const tab = storeRef.tabName.value
  const curTab = props.tabType
  if(tab != curTab)
    return

  const user = JSON.parse(localStorage.getItem('user'))
  let memberIdx = user.memberIdx
  let deptIdx = user.deptIdx
  //1,3은 개인 2,4는 부서
  if(props.tabType == 'tabCont1' || props.tabType == 'tabCont3')
    deptIdx = 0
  else
    memberIdx = 0

  let params = {
    memberIdx:memberIdx,
    deptIdx:deptIdx,
  }
  TimelineService.getTimelineSummary(params).then(
    (response) => {
      //summary 갯수
      const totalReceive = response.result.receiveTimelines
      badCnt.value = _.filter(totalReceive, {type:'1'}).length
      goodCnt.value = _.filter(totalReceive, {type:'2'}).length
      completeCnt.value = _.filter(totalReceive, {type:'1', status:'4'}).length
      delayCnt.value = _.filter(totalReceive, {type:'1', status:'3'}).length
      ingCnt.value = _.filter(totalReceive, {type:'1', status:'2'}).length
      //안전대화, 지지적 분리
      receiveItems.value = _.filter(response.result.receiveTimelines, {type:(props.tabType == 'tabCont1' || props.tabType == 'tabCont2')?'1':'2'})
      //받은목록 5개까지만
      receiveItems.value = _.slice(receiveItems.value, 0, 5)
      //상세데이터 변경
      _.forEach(receiveItems.value, function(val, key) {
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.targetText = val.targetDeptName
        else if(val.targetType == '3') val.targetText = val.targetMemberName
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })
      //보낸목록 5개까지만
      sendItems.value = _.filter(response.result.sendTimelines, {type:(props.tabType == 'tabCont1' || props.tabType == 'tabCont2')?'1':'2'})
      sendItems.value = _.slice(sendItems.value, 0, 5)
      _.forEach(sendItems.value, function(val, key) {
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.targetText = val.targetDeptName
        else if(val.targetType == '3') val.targetText = val.targetMemberName
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickView(type){
  //receive, send
  storeRef.senderType.value = type
  storeRef.isTabView1.value = true
}
function getColor(goalDate) {
  const startDate = dayjs();
  const endDate = dayjs(goalDate);
  const duration = endDate.diff(startDate, 'days');
  if (duration < 7)
    return 'txt-red'
}
function getColor2(status) {
  if (status == '2') return 'txt-blue'
  else if (status == '3') return 'txt-red'
}
</script>


<style lang="scss" >
</style>
