<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">{{dialogTit}}</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="dialog-txt" v-html="dialogText"></div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 v-bind="btnCancel" v-if="btn2Show" @click.prevent="onClickCancel"/>
          <c-btn01 v-bind="btnConfirm" v-if="btn1Show" @click.prevent="onClickConfirm"/>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const props = defineProps(['dialogText', 'dialogTit','btn1Text','btn1Show','btn2Text','btn2Show'])
const emit = defineEmits(['close', 'confirm', 'cancel'])
const dialog = defineModel()

//ui
const btnCancel = {
  btnText: props.btn2Text,
}
const btnConfirm = {
  btnText: props.btn1Text,
}

//function
function onClickConfirm(){
  emit('confirm',null)
  dialog.value = false
}

function onClickCancel(){
  emit('cancel',null)
  dialog.value = false
}
</script>


<style lang="scss">
.dialog-txt{
  letter-spacing:0;
  font-size:1.6rem;
  line-height:2.4rem;
  color:var(--color-black)

}
</style>
