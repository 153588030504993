<template>
    <v-dialog
      v-model="dialog"
      width="auto"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="img-box">
            <img :src="src" />
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'닫기'" @click="dialog=false"/>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const props = defineProps(['src'])
const dialog = defineModel()
const src = ref('')

//hook
onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    src.value = 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
  }
  else {
    src.value = props.src
  }
})
</script>


<style lang="scss">
</style>
