<template>
  <div class="content-box bg-none">
    <div class="top-cont">
      <div class="switch-group">
        <div class="col">
          <div class="tit">안전관찰</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
        <div class="col ty01">
          <div class="tit">안전리더십</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
        <div class="col ty02">
          <div class="tit">시정조치</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
        <c-btn02 class="sm" btn-text="* <모듈선택> 팝업 테스트" @click="DialogModuleModel=true"/>
      </div>
    </div>
    <div id="example-full" class="safety-calendar">
      <div class="calendar-parent">
        <CalendarView
          :items="state.items"
          :show-date="state.showDate"
          :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
          :enable-drag-drop="true"
          :disable-past="state.disablePast"
          :disable-future="state.disableFuture"
          :show-times="state.showTimes"
          :date-classes="myDateClasses()"
          :display-period-uom="state.displayPeriodUom"
          :display-period-count="state.displayPeriodCount"
          :starting-day-of-week="state.startingDayOfWeek"
          :class="themeClasses"
          :period-changed-callback="periodChanged"
          :current-period-label="state.useTodayIcons ? 'icons' : ''"
          :displayWeekNumbers="state.displayWeekNumbers"
          :enable-date-selection="true"
          :selection-start="state.selectionStart"
          :selection-end="state.selectionEnd"
          @date-selection-start="setSelection"
          @date-selection="setSelection"
          @date-selection-finish="finishSelection"
          @drop-on-date="onDrop"
          @click-date="onClickDay"
          @click-item="onClickItem"
        >
          <template #header="{ headerProps }">
            <CalendarViewHeader :header-props @input="setShowDate" />
          </template>
        </CalendarView>
      </div>
    </div>
  </div>
  <DialogModule v-model="DialogModuleModel"
                @close="DialogModuleModel=false"
  />
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from 'vue';
import CalendarView from "../components/scalendar/CalendarView.vue"
import CalendarViewHeader from "../components/scalendar/CalendarViewHeader.vue"
import CalendarMath from "../components/scalendar/CalendarMath"
import { ICalendarItem, INormalizedCalendarItem } from "../components/scalendar/ICalendarItem"

const DialogModuleModel = ref(false)
const thisMonth = (d: number, h?: number, m?: number): Date => {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
}

interface IExampleState {
  showDate: Date
  message: string
  startingDayOfWeek: number
  disablePast: boolean
  disableFuture: boolean
  displayPeriodUom: string
  displayPeriodCount: number
  displayWeekNumbers: boolean
  showTimes: boolean
  selectionStart?: Date
  selectionEnd?: Date
  newItemTitle: string
  newItemStartDate: string
  newItemEndDate: string
  useDefaultTheme: boolean
  useHolidayTheme: boolean
  useTodayIcons: boolean
  items: ICalendarItem[]
}

const state = reactive({
  /* Show the current month, and give it some fake items to show */
  showDate: thisMonth(1),
  message: "",
  startingDayOfWeek: 0,
  disablePast: false,
  disableFuture: false,
  displayPeriodUom: "month",
  displayPeriodCount: 1,
  displayWeekNumbers: false,
  showTimes: true,
  selectionStart: undefined,
  selectionEnd: undefined,
  newItemTitle: "",
  newItemStartDate: "",
  newItemEndDate: "",
  useDefaultTheme: true,
  useHolidayTheme: true,
  useTodayIcons: true,
  items: [
    /*{
      id: "e0",
      startDate: "2018-01-05",
    },*/
    {
      id: "e1",
      startDate: thisMonth(3),
      classes: ["sort01"],
      title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
    },
    {
      id: "e2",
      startDate: thisMonth(3),
      classes: ["sort02"],
      title: "안전리더십 1회차",
      tooltip: "안전리더십 1회차",
    },
    {
      id: "e3",
      startDate: thisMonth(3),
      classes: ["sort03"],
      title: "시정조치 A업무",
      tooltip: "시정조치 A업무",
    },
    {
      id: "e4",
      startDate: thisMonth(5),
      classes: ["sort01"],
      title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
    },
    {
      id: "e5",
      startDate: thisMonth(5),
      classes: ["sort03"],
      title: "시정조치 A업무",
      tooltip: "시정조치 A업무",
    },
    {
      id: "e5",
      startDate: thisMonth(5),
      classes: ["sort04"],
      title: "3 more",
    },
    {
      id: "e6",
      startDate: thisMonth(18),
      classes: ["sort01"],
      title: "평택지사 안전관찰 1회차 안전관찰안전관찰",
      tooltip: "평택지사 안전관찰 1회차 안전관찰안전관찰",
    },
    {
      id: "e7",
      startDate: thisMonth(18),
      classes: ["sort03"],
      title: "시정조치 A업무",
      tooltip: "시정조치 A업무",
    },
  ],
} as IExampleState)

const userLocale = computed((): string => CalendarMath.getDefaultBrowserLocale())

const dayNames = computed((): string[] => CalendarMath.getFormattedWeekdayNames(userLocale.value, "long", 0))

const themeClasses = computed(() => ({
  "theme-default": state.useDefaultTheme,
  "holiday-us-traditional": state.useHolidayTheme,
  "holiday-us-official": state.useHolidayTheme,
}))

const myDateClasses = (): Record<string, string[]> => {
  // This was added to demonstrate the dateClasses prop. Note in particular that the
  // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
  // for those keys are strings or string arrays. Keep in mind that your CSS to style these
  // may need to be fairly specific to make it override your theme's styles. See the
  // CSS at the bottom of this component to see how these are styled.
  const o = {} as Record<string, string[]>
  const theFirst = thisMonth(1)
  const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13
  const idesDate = thisMonth(ides)
  o[CalendarMath.isoYearMonthDay(idesDate)] = ["ides"]
  o[CalendarMath.isoYearMonthDay(thisMonth(21))] = ["do-you-remember", "the-21st"]
  return o
}

onMounted((): void => {
  state.newItemStartDate = CalendarMath.isoYearMonthDay(CalendarMath.today())
  state.newItemEndDate = CalendarMath.isoYearMonthDay(CalendarMath.today())
})

const periodChanged = (): void => {
  // range, eventSource) {
  // Demo does nothing with this information, just including the method to demonstrate how
  // you can listen for changes to the displayed range and react to them (by loading items, etc.)
  //console.log(eventSource)
  //console.log(range)
}

const onClickDay = (d: Date): void => {
  state.selectionStart = undefined
  state.selectionEnd = undefined
  state.message = `You clicked: ${d.toLocaleDateString()}`
}

const onClickItem = (item: INormalizedCalendarItem): void => {
  state.message = `You clicked: ${item.title}`
}

const setShowDate = (d: Date): void => {
  state.message = `Changing calendar view to ${d.toLocaleDateString()}`
  state.showDate = d
}

const setSelection = (dateRange: Date[]): void => {
  state.selectionEnd = dateRange[1]
  state.selectionStart = dateRange[0]
}

const finishSelection = (dateRange: Date[]): void => {
  setSelection(dateRange)
  state.message = `You selected: ${state.selectionStart?.toLocaleDateString() ?? "n/a"} - ${state.selectionEnd?.toLocaleDateString() ?? "n/a"}`
}

const onDrop = (item: INormalizedCalendarItem, date: Date): void => {
  state.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
  // Determine the delta between the old start date and the date chosen,
  // and apply that delta to both the start and end date to move the item.
  const eLength = CalendarMath.dayDiff(item.startDate, date)
  item.originalItem.startDate = CalendarMath.addDays(item.startDate, eLength)
  item.originalItem.endDate = CalendarMath.addDays(item.endDate, eLength)
}

const clickTestAddItem = (): void => {
  state.items.push({
    startDate: CalendarMath.fromIsoStringToLocalDate(state.newItemStartDate),
    endDate: CalendarMath.fromIsoStringToLocalDate(state.newItemEndDate),
    title: state.newItemTitle,
    id: "e" + Math.random().toString(36).substring(2, 11),
  })
  state.message = "You added a calendar item!"
}

</script>


<style lang="scss">
/*
@import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";
*/
@import "../assets/css/scalendar/gcal.css";
/*휴일 표시 삭제*/
/*
@import "../assets/css/scalendar/holidays-us.css";
@import "../assets/css/scalendar/holidays-ue.css";
 */
#example-full {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80rem;
  margin-top:0.9rem;
  margin-left: auto;
  margin-right: auto;
}

#example-full .calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}

#example-full .calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*overflow-x: hidden;*/
  /*overflow-y: hidden;*/
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
#example-full .cv-wrapper.period-month.periodCount-2 .cv-week,
#example-full .cv-wrapper.period-month.periodCount-3 .cv-week,
#example-full .cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}
/*
#example-full .theme-default .cv-day.ides{
  background-color: #ffe0e0;
}
*/
</style>
