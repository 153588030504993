<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="57rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="dropdown-group ty01">
          <v-list v-model:opened="listOpen">
            <!--depth1 -->
            <v-list-group v-for="(itemD1, seqD1) in filterArray(0)" :key="itemD1.idx">
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" :title="itemD1.treeName" :class="itemD1.selected?'on':''">
                  <div class="check-group">
                    <v-checkbox
                      :model-value="false"
                      @click.stop="onCheckItem(itemD1)"
                    ></v-checkbox>
                  </div>
                </v-list-item>
              </template>
              <!--depth2 -->
              <v-list-group v-for="(itemD2, seqD2) in filterArray(itemD1.idx)">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="itemD2.treeName" :class="itemD2.selected?'on':''">
                    <div class="check-group">
                      <v-checkbox
                        :model-value="false"
                        @click.stop="itemD2.selected = !itemD2.selected"
                      ></v-checkbox>
                    </div>
                  </v-list-item>
                </template>
                <!--depth3 -->
                <v-list-group v-for="(itemD3, seqD3) in filterArray(itemD2.idx)">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :title="itemD3.treeName" :class="itemD3.selected?'on':''">
                      <div class="check-group">
                        <v-checkbox
                          :model-value="false"
                          @click.stop="itemD3.selected = !itemD3.selected"
                        ></v-checkbox>
                      </div>
                    </v-list-item>
                  </template>
                  <!--depth4 필요하면 추가 -->
                  <v-list-group v-for="(itemD4, seqD4) in filterArray(itemD3.idx)">
                    <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" :title="itemD4.treeName" :class="itemD4.selected?'on':''">
                        <div class="check-group">
                          <v-checkbox
                            :model-value="false"
                            @click.stop="itemD4.selected = !itemD4.selected"
                          ></v-checkbox>
                        </div>
                      </v-list-item>
                    </template>
                  </v-list-group>
                  <!--depth4 -->
                </v-list-group>
                <!--depth3 -->
              </v-list-group>
              <!--depth2 -->
            </v-list-group>
            <!--depth1 -->
          </v-list>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class=" d-flex justify-end"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave" @click.prevent.stop="handleForm"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import {ref, computed} from 'vue'
import CompanyService from "@/services/company.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//ui
const btnSave = {
  btnText: '확인',
}

//bind
const listOpen = ref(['open'])
const items:any = ref([])
const popTitle = ref('')

//hook
onBeforeUpdate (()=>{
  items.value = []
})

onUpdated (() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        parentIdx: 0,
        treeName: 'A경영지원본부',
        treeLevel: 1,
        selected: false,
      },
      {
        idx: 2,
        parentIdx: 1,
        treeName: 'A경영지원본부2',
        treeLevel: 2,
        selected: false,
      },
      {
        idx: 3,
        parentIdx: 1,
        treeName: 'A경영지원본부3',
        treeLevel: 2,
        selected: false,
      },
    ]
  }
  else {
    if (!storeRef.popupIdx.value)
      return;

    if (dialog.value) {
      let params = {idx: 0, companyIdx: storeRef.popupIdx.value, treeType: storeRef.popupType.value,}
      CompanyService.getCompanyTreeList(params).then(
        (response) => {
          items.value = response.result
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})

const filterArray = computed(()=>(param) => {return _.filter(items.value, {parentIdx: param}) })

function onCheckItem(item){
  //todo 나중에 single 처리
  if(true) {
    _.forEach(items.value, function (val, key) {
      if(val == item)
        val.selected = true
      else
        val.selected = false
    })
  }
  else
    item.selected = !item.selected
}

function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else {
    emit('confirm', _.filter(items.value, {selected: true}))
  }
}
</script>


<style lang="scss">

</style>
