<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="96rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <v-card-text>
          <p class="fs12">총 {{periodCnt}}차수가 검색되었습니다.</p>

          <v-divider class="mb-4"></v-divider>
          <div class="tbl-group line-ty tbl-fixed size-custom16">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer>
              <template v-slot:item.author="{ item }" >
                <div>
                  {{item.author}}
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'확인'"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const dialog = defineModel()

//bind
const headers = ref([])
const items = ref([])
const periodCnt = ref(0)

//ui

//hook
onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    headers.value = [
      { title: '작성자', key: 'author' },
      { title: '모니터링 총계', key: 'monitoringTotal' },
      { title: '안전대화 총계', key: 'safetyTalkTotal' },
      { title: '1차수 5.1~5.6', key: 'st01' },
      { title: '2차수 5.1~5.6', key: 'st02' },
      { title: '3차수 5.1~5.6', key: 'st03' },
      { title: '4차수 5.1~5.6', key: 'st04' },
      { title: '5차수 5.1~5.6', key: 'st05' },
      { title: '6차수 5.1~5.6', key: 'st06' },
      { title: '7차수 5.1~5.6', key: 'st07' },
      { title: '8차수 5.1~5.6', key: 'st08' },
    ]
    items.value = [
      {
        author: '김상팔 / 부서 / 직책',
        monitoringTotal: '2',
        safetyTalkTotal: '4',
        st01: '10/2',
        st02: '10/2',
        st03: '10/2',
        st04: '10/2',
        st05: '10/2',
        st06: '10/2',
        st07: '10/2',
        st08: '10/2',
      },
      {
        author: '김상팔 / 부서 / 직책',
        monitoringTotal: '2',
        safetyTalkTotal: '4',
        st01: '10/2',
        st02: '10/2',
        st03: '10/2',
        st04: '10/2',
        st05: '10/2',
        st06: '10/2',
        st07: '10/2',
        st08: '10/2',
      },
      {
        author: '김상팔 / 부서 / 직책',
        monitoringTotal: '2',
        safetyTalkTotal: '4',
        st01: '10/2',
        st02: '10/2',
        st03: '10/2',
        st04: '10/2',
        st05: '10/2',
        st06: '10/2',
        st07: '10/2',
        st08: '10/2',
      }
    ]
  }
  else {
    if(dialog.value)
      onClickSearch()
  }
})

function onClickSearch(){
  let params = storeRef.popupParams.value

  BbsService.getMonitoringPeriodMemberCnt(params).then(
    (response) => {
      const memberArr = _.uniq(_.map(response.result, 'memberIdx'));
      const periodArr = _.uniq(_.map(response.result, 'monitoringUniq'));
      periodCnt.value = periodArr.length
      //header 셋팅
      headers.value = [
        { title: '작성자', key: 'author' },
        { title: '모니터링 총계', key: 'monitoringTotal' },
        { title: '안전대화 총계', key: 'safetyTalkTotal' },
      ]
      _.forEach(periodArr, function(val, key) {
          headers.value.push({title:val, key:val})
      })
      //맴버별 로우 셋팅
      let tempItems = []
      _.forEach(memberArr, function(val, key) {
        const tempMemberInfo = _.find(response.result, {memberIdx:val})
        let totalAnswerCnt = 0
        let totalTimelineCnt = 0
        let resultObj = {
          author: tempMemberInfo.memberName + '/' + tempMemberInfo.deptName + '/' + tempMemberInfo.posName,
          monitoringTotal: 0,
          safetyTalkTotal: 0,
        }
        _.forEach(periodArr, function(val2, key2) {
          const tempMemberInfo2 = _.find(response.result, {memberIdx:tempMemberInfo.memberIdx, monitoringUniq:val2})
          resultObj[val2] = tempMemberInfo2.answerCnt?tempMemberInfo2.answerCnt:0 + '/' + tempMemberInfo2.timelineCnt?tempMemberInfo2.timelineCnt:0
          totalAnswerCnt += tempMemberInfo2.answerCnt?tempMemberInfo2.answerCnt:0
          totalTimelineCnt += tempMemberInfo2.timelineCnt?tempMemberInfo2.timelineCnt:0
        })
        resultObj.monitoringTotal = totalAnswerCnt
        resultObj.safetyTalkTotal = totalTimelineCnt
        tempItems.push(resultObj)
      })
      items.value = tempItems
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>


<style lang="scss">
</style>
