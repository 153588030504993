<template>
  <div class="tit-group">
    <h2 class="tit">그룹관리</h2>
  </div>
  <div class="card-group">
    <!--card-col-->
    <div class="card-col" v-for="item in items">
      <div class="card">
        <v-btn class="btn-card-set" @click.stop="showPopup(item.idx)">
          <span class="hide-txt">설정</span>
        </v-btn>
        <div class="tit ell02">
          {{item.companyName}}
        </div>
        <div class="btn-group">
          <v-btn class="btn-card-connect" @click.stop="onClickGoList(item.idx, item.companyName)">접속</v-btn>
          <v-btn class="btn-card-copy" @click="dialogPageCopyModel=true">복사</v-btn>
        </div>
      </div>
    </div>
    <v-btn class="btn-card-add" @click.stop="showPopup(0)">
      <div class="inner">그룹 신규 생성</div>
    </v-btn>
  </div>
  <!--그룹정보-->
  <GmDialog01 v-model="groupDialogModel" @close="closePopup"/>
  <!--복사-->
  <c-dialog v-model="dialogPageCopyModel"
            v-bind="dialogPageCopyBind"
            @close="dialogPageCopyModel=false"/>
</template>
<style lang="scss">
:root{
  /*card-group*/
  --color-card-bg1:#3C50E0;
  --color-card-bg2:#737C8F;
  --color-card-border:#8D97A5;
  --color-card-txt:#5E626B;
  --color-card-ico-bg:#B1B8C6;
}
.card-group{
  display:flex;
  margin-top:1.5rem;
  flex-wrap: wrap;
  .card-col{
    display:flex;
    margin-right:3rem;
    margin-bottom:3rem;
    &:nth-child(2n) .card{
      background:var(--color-card-bg2);
    }
    &:last-child{margin-right:0}
    .card{
      position:relative;
      display:flex;
      flex-direction:column;
      justify-content: space-between;
      width:34rem;
      height:19rem;
      padding:3rem 2rem 2rem 2.2rem;
      background:var(--color-card-bg1);
      border-radius:2rem;
      .tit{
        padding-right:5rem;
        font-size:2.2rem;
        line-height:3.3rem;
        height:6.6rem;
        color:var(--color-white);
        font-weight:600;
      }
      .btn-card-set{
        position:absolute;
        top:2rem;
        right:2rem;
        width:4rem;
        min-width:4rem;
        height:4rem;
        background:rgba(0,0,0,0.14) url("@/assets/img/ico-card-set.svg") center no-repeat;
        color:var(--color-white);
        background-size:1.8rem auto;
        box-shadow:none;
        border-radius:100%;
      }
      .btn-group{
        text-align:right;
        [class*=btn-]{
          height:4rem;
          padding:0 2rem 0 4.6rem;
          background-color:rgba(0,0,0,0.14);
          font-size:1.6rem;
          font-weight:600;
          color:var(--color-white);
          box-shadow:none;
          border-radius:2rem;
          background-position:1.8rem center;
          background-repeat:no-repeat;
          background-size:2rem auto;
        }
        [class*=btn-] + [class*=btn-]{margin-left: .9rem;}
        .btn-card-connect{
          background-image:url("@/assets/img/ico-card-connect.svg");
        }
        .btn-card-copy{
          background-image:url("@/assets/img/ico-card-copy.svg");
        }
      }
    }
  }
  .v-btn.btn-card-add{
    width:34rem;
    height:19rem;
    border:1px dashed var(--color-card-border);
    border-radius:2rem;
    box-shadow:none;
    font-size:2.2rem;
    line-height:3.3rem;
    color:var(--color-card-txt);
    font-weight:600;
    .inner:before{
      content:'';
      display:block;
      margin:-.7rem auto 1.2rem;
      width:5.2rem;
      height:5.2rem;
      background:var(--color-card-ico-bg) url("@/assets/img/ico-card-plus.svg") center no-repeat;
      background-size:2.4rem auto;
      border-radius:100%;
    }
  }
}
</style>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import {useRoute} from "vue-router";
import OrganizationService from "@/services/organization.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";

//store
const route = useRoute()
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const groupDialogModel = ref(false)
const items = ref([])
const dialogPageCopyModel = ref(false)

//ui
const dialogPageCopyBind = {
  dialogTit: '복사',
  dialogText: '선택하신 회사를 복사하시겠습니까? ',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}

//hook
onMounted(() => {
  if (import.meta.env.MODE == 'development') {
    items.value = [
      {
        title: '삼성평택지사'
      },
      {
        title: '그룹명이 들어갑니다.그룹명이 들어갑니다.그룹명이 들어갑니다. '
      },
      {
        title: '삼성평택지사'
      },
      {
        title: '삼성평택지사'
      },
      {
        title: '그룹명이 들어갑니다.그룹명이 들어갑니다.그룹명이 들어갑니다. '
      },
      {
        title: '삼성평택지사'
      },
    ]
  }
  else{
    onClickSearch()
  }
})

//function
function onClickSearch(){
  const clientIdx = localStorage.getItem('clientIdx')
  if(utils.isEmpty(clientIdx))
    utils.movePage('/login')

  let params = {
    clientIdx: clientIdx,
    delYn: 'N'
  }

  OrganizationService.getCompanyListAdm(params).then(
    (response) => {
      if(response.result)
        items.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function showPopup(idx){
  storeRef.popupIdx.value = idx
  groupDialogModel.value = true
}

function closePopup(type){
  groupDialogModel.value = false
  if(type == 'refresh')
    onClickSearch()
}

function onClickGoList(idx, name){
  localStorage.setItem('companyIdx', idx)
  storeRef.user.value.companyName = name
  utils.movePage('/safetyCalender')
}
</script>
