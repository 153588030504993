<template>
  <div class="tit-group">
    <h2 class="tit">언어 설정</h2>
  </div>
  <div class="content-box">

    <div class="sort-group">
      <div class="sort-row">
        <div class="wl">
          <c-btn02 :btnText="'업로드'"/>
          <c-btn02 :btnText="'다운로드'"/>
        </div>
        <div class="wr">
          <Select v-bind="select01"/>
          <c-btn01 :btnText="'언어추가'"/>
          <c-btn01 :btnText="'저장'" @click="dialogSaveModel=true"/>

        </div>
      </div>
    </div>
    <div class="tbl-group02">
      <table>
        <colgroup>
          <col style="width:18%">
          <col>
          <col style="width:30%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th>
            <div>모듈</div>
          </th>
          <th>
            <div class="flex-l">항목</div>
          </th>
          <th>
            <div class="flex-r">
              <c-tooltip :TooltipText="'언어 변경 버튼에서 노출되는 명칭입니다.'"/>
              <c-Input/>
              </div>
          </th>
          <th>
            <div class="flex-r">
              <c-Input/>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="4">
            <div class="bold">행동기반 안전관리</div>
          </td>
          <td>
            <div class="flex-l bold">모니터링</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <td rowspan="4">
            <div class="bold">행동기반 안전관리</div>
          </td>
          <td>
            <div class="flex-l bold">모니터링</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-l bold">확인</div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
          <td>
            <div class="flex-r">
              <c-Input/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <!--변경정보-->
  <c-dialog v-model="dialogSaveModel"
            v-bind="dialogSave"
            @close="dialogSaveModel=false"
  />
</template>
<style lang="scss" >
.tbl-group .v-table .v-table__wrapper > table > tbody > tr > td:nth-child(2){width:30%;}
</style>
<script lang="ts" setup>
import { ref } from 'vue'
const isShowDialog = ref(false)
const select01 = {
  select:  '1-admin(2023.03.02)',
  items: ['1-admin(2023.03.02)', '2-admin(2023.03.02)', '3-admin(2023.03.02)']
}
const dialogSaveModel = ref(false)
const dialogSave= {
  dialogTit: '알림',
  dialogText: '저장하면 현재 기준으로 신규버전이 생성됩니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
</script>
