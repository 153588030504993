<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="auto"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">모니터링 결과 상세</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text>
          <div class="tbl-group line-ty tbl-fixed c01">
            <v-table>
              <thead>
                <tr>
                    <th rowspan="2">분류</th>
                    <th colspan="3"  v-for="n in 10" :key="n">
                        1회차
                      <span class="t1">23.01.01~23.02.01</span>
                    </th>
                </tr>
                <tr>
                  <template v-for="n in 10" :key="n">
                    <th>셀프</th>
                    <th>타인</th>
                    <th>격차</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>동행점검</td>
                  <td v-for="n in 30" :key="n">3</td>
                </tr>
                <tr>
                  <td>일일미팅</td>
                  <td v-for="n in 30" :key="n">3</td>
                </tr>
                <tr>
                  <td>화기작업</td>
                  <td v-for="n in 30" :key="n">3</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>총합</td>
                  <td v-for="n in 30" :key="n">10</td>
                </tr>
              </tfoot>
            </v-table>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 btnText="확인"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Sortable } from 'sortablejs-vue3';
const dialog = defineModel()
</script>


<style lang="scss" scoped>
</style>
