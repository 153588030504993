<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">모듈선택</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>

        <div class="selected-group ty01">
          <v-item-group selected-class="bg-active">
            <v-container>
              <div class="list-group">
                <div class="list-row">
                  <div class="list-txt">
                    <v-item v-slot="{ selectedClass, toggle }">
                      <v-card
                        :class="['active', selectedClass]"
                        dark
                        @click="toggle"
                      >
                        <!--
                        sort01 안전관찰
                        sort02 안전리더십
                        sort03 시정조치
                        -->
                        <div class="txt sort01">
                          안전관찰A
                        </div>
                      </v-card>
                    </v-item>
                  </div>
                </div>
                <div class="list-row">
                  <div class="list-txt">
                    <v-item v-slot="{ selectedClass, toggle }">
                      <v-card
                        :class="['active', selectedClass]"
                        dark
                        @click="toggle"
                      >
                        <!--
                        sort01 안전관찰
                        sort02 안전리더십
                        sort03 시정조치
                        -->
                        <div class="txt sort02">
                          안전리더십
                        </div>
                      </v-card>
                    </v-item>
                  </div>
                </div>
                <div class="list-row">
                  <div class="list-txt">
                    <v-item v-slot="{ selectedClass, toggle }">
                      <v-card
                        :class="['active', selectedClass]"
                        dark
                        @click="toggle"
                      >
                        <!--
                        sort01 안전관찰
                        sort02 안전리더십
                        sort03 시정조치
                        -->
                        <div class="txt sort03">
                          시정조치
                        </div>
                      </v-card>
                    </v-item>
                  </div>
                </div>
              </div>
            </v-container>
          </v-item-group>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end"><!--pop-bottom-bg-->
        <c-btn02 btnText="닫기"/>
        <c-btn01 btnText="확인"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const props = defineProps(['popTitle'])
const dialog = defineModel()
</script>


<style lang="scss">

</style>
