<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="127rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세설정</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text>
          <div class="form-group col-ty">
            <!--form-row-->
            <div class="form-row ty04">
              <div class="txt-tit ty02">과제명</div>
              <div class="row">
                <c-Input :placeholder="'제목을 입력하세요'" class="size06"/>
              </div>
            </div>
            <!--form-row-->
            <div class="form-row ty04">
              <div class="txt-tit ty02">설명</div>
              <div class="row">
                <c-Input :placeholder="'과제에 대한 세부 설명을 입력 해주세요'" class="size06"/>
              </div>
            </div>
          </div>
          <div class="sort-group ty02">
            <div class="sort-row">
              <div class="wl">
                <div class="txt04">안전리더십 과제 설정</div>
              </div>
              <div class="wr">
                <c-btn01 :btnText="'과제 추가'" class="sm"/>
                <c-btn02 :btnText="'평가 병합'" class="sm"/>
                <c-btn02 :btnText="'평가 분할'" class="sm"/>
              </div>
            </div>
          </div>
          <div class="sortable-group02">
            <div class="sortable-header">
              <div class="col">노출<br>순서</div>
              <div class="col">제목</div>
              <div class="col">성과 반영<br>여부</div>
              <div class="col">중복 제출<br>허용</div>
              <div class="col">평가타입</div>
              <div class="col">평가기준</div>
              <div class="col">주기</div>
              <div class="col">횟수</div>
              <div class="col">평가<br>가중치</div>
              <div class="col">평가병합<br>가중치</div>
              <div class="col">상세<br>설정</div>
              <div class="col">삭제</div>
            </div>
            <Sortable
              :list="testItems"
              item-key="id"
              tag="div"
              class="sortable-cont"
            >
              <template #item="{element, index}">
                <div class="draggable">
                  <div class="row" v-if="element.type == '1' || element.type == '2'">
                    <div class="col" aria-hidden="true">
                      <img src="../../assets/img/format-align-justify.svg" alt="">
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input :placeholder="'제목'" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select011" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select02" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select03" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06" :disabled="true"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-set" @click="DialogSafetyDetailSetTy1Model=true"/>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-delete"/>
                    </div>
                  </div>
                  <div class="row" v-if="element.type == '2'">
                    <div class="col" aria-hidden="true"></div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input :placeholder="'제목'" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select012" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select02" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select03" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06" :disabled="true"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-set" @click="DialogSafetyDetailSetTy2Model=true"/>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-delete"/>
                    </div>
                  </div>
                  <div class="row" v-if="element.type == '3'">
                    <div class="col" aria-hidden="true">
                      <img src="../../assets/img/format-align-justify.svg" alt="">
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input :placeholder="'제목'" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="check-group">
                        <v-checkbox></v-checkbox>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select013" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select02" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <Select v-bind="select03" class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06" :disabled="true"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <c-Input02 class="size06"/>
                      </div>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-set" @click="DialogSafetyDetailSetTy3Model=true"/>
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-delete"/>
                    </div>
                  </div>
                </div>
              </template>
            </Sortable>
            <div class="sortable-footer">
              <div class="col">총합</div>
              <div class="col">평가가중치 총 <span>100</span></div>
            </div>
          </div>

        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 btnText="취소"/>
          <c-btn01 btnText="저장"/>
        </v-card-actions>
      </v-card>
      <!--popup: 상세설정:셀프모니터링-->
      <DialogSafetyDetailSetTy1 v-model="DialogSafetyDetailSetTy1Model"
                                @close="DialogSafetyDetailSetTy1Model=false"
      />
      <!--popup: 상세설정:퀴즈형식-->
      <DialogSafetyDetailSetTy2 v-model="DialogSafetyDetailSetTy2Model"
                                @close="DialogSafetyDetailSetTy2Model=false"
      />
      <!--popup: 상세설정:자유형식-->
      <DialogSafetyDetailSetTy3 v-model="DialogSafetyDetailSetTy3Model"
                                @close="DialogSafetyDetailSetTy3Model=false"
      />
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
const dialog = defineModel()
const DialogSafetyDetailSetTy1Model = ref(false)
const DialogSafetyDetailSetTy2Model = ref(false)
const DialogSafetyDetailSetTy3Model = ref(false)
const testItems = [
  {
    class: 'active',
    type: '1'
  },
  {
    type: '2'
  },
  {
    type: '3'
  }
]
const select011 = {
  select:  '셀프모니터링',
  items: ['셀프모니터링', '퀴즈형식', '자유형식']
}
const select012 = {
  select:  '퀴즈형식',
  items: ['셀프모니터링', '퀴즈형식', '자유형식']
}
const select013 = {
  select:  '자유형식',
  items: ['셀프모니터링', '퀴즈형식', '자유형식']
}
const select02 = {
  select:  '완료여부',
  items: ['완료여부', '퀴즈점수', '자유형식'],
  disabled: true
}
const select03 = {
  select:  '1주',
  items: ['1주', '2주', '한달']
}
</script>


<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left}
    .col:nth-child(3){flex-grow:0;width:6%;}
    .col:nth-child(4){flex-grow:0;width:6%;}
    .col:nth-child(5){flex-grow:0;width:12%;}
    .col:nth-child(6){flex-grow:0;width:12%;}
    .col:nth-child(7){flex-grow:0;width:8%;}
    .col:nth-child(8){flex-grow:0;width:6%;}
    .col:nth-child(9){flex-grow:0;width:6%;}
    .col:nth-child(10){flex-grow:0;width:6%;}
    .col:nth-child(11){flex-grow:0;width:5%;}
    .col:nth-child(12){flex-grow:0;width:5%;}
  }
  .sortable-footer{
    .col:nth-child(1){flex-grow:0;width:16%;}
    .col:nth-child(2){flex-grow:0;width:38%;}
  }
}
</style>
