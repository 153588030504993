<template>
  <div class="content-wrap">
    <NaviBox02/>
    <div class="tit-group02">
      <h2 class="tit">안전리더십계획 관리</h2>
    </div>
    <div class="content-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: 132</div>
            <c-InputSearch :placeholder="'콤마로 다중 검색 가능'"/>
          </div>
          <div class="wr">
            <c-btn01 :btnText="'신규등록'" @click="utils.movePage('/safetyLeadershipManagementDetail')"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom6">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          show-select
        >
          <template v-slot:item.title="{ item }">
            <div class="flex-l">
              <div class="chk-favorites-ty">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <v-btn variant="text" @click="utils.movePage('/safetyLeadershipManagementDetail')">
                <div class="ell01">
                  {{item.title}}
                </div>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btnLine01 :btnText="'일괄 대상자 변경'"  @click="isShowDialog=true"/>
          <c-btnLine01 :btnText="'일괄 상태 변경'"  @click="dialogStateChangeModel=true"/>
          <c-btn02 :btnText="'일괄 숨기기'"/>
          <c-btn02 :btnText="'일괄 삭제'"  @click="dialogAllDeleteModel=true"/>
        </div>
      </div>
    </div>
  </div>
 <!--
  <c-dialog v-model="dialogSubjectChangeModel"
            v-bind="dialogSubjectChangeBind"
            @close="dialogSubjectChangeModel=false"
  />
  -->
  <!--일괄 대상자 변경-->
  <DialogTargetAdd2
    v-model="isShowDialog"
    @close="isShowDialog=false"
  />
  <c-dialog v-model="dialogCopyModel"
            v-bind="dialogCopyBind"
            @close="dialogCopyModel=false"
  />
  <c-dialog v-model="dialogStateChangeModel"
            v-bind="dialogStateChangeBind"
            @close="dialogStateChangeModel=false"
  />
  <c-dialog v-model="dialogAllDeleteModel"
            v-bind="dialogAllDeleteBind"
            @close="dialogAllDeleteModel=false"
  />

</template>

<script setup lang="ts">
import { ref } from 'vue';
import utils from "@/common/Utils";

const isShowDialog = ref(false)
const dialogCopyModel = ref(false)
const dialogStateChangeModel = ref(false)
const dialogAllDeleteModel = ref(false)
const dialogCopyBind = {
  dialogTit: '행동 복사',
  dialogText: '선택된 모니터링을 복사합니다.<br>진행중인 대상을 신규모니터링에도 추가할까요?<br>단, 대상자 복사 시 즉시 노출을 방지하기 위해 모니터링은 종료된 채로 복사 됩니다.',
  btn1Text: '대상 포함 복사',
  btn1Show: true,
  btn2Text: '대상 미포함 복사',
  btn2Show: true,
}
const dialogStateChangeBind = {
  dialogTit: '모니터링 상태 변경',
  dialogText: '선택된 모니터링을 활성화/비활성화 합니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const dialogAllDeleteBind = {
  dialogTit: '행동 삭제',
  dialogText: '선택된 행동을 삭제합니다.<br>삭제된 데이터는 복구가 불가능합니다.',
  btn1Text: '삭제',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const select01 = {
  select:  '전체',
  items: ['전체', '행동', '상태']
}
//table
const btnCopy = {
  btnText: '복사'
}
const selected = ref([])
const headers = ref([
  { title: '제목', key: 'title' },
  { title: '대상자수', key: 'subjectNum' },
  { title: '기간', key: 'period'},
  { title: '타입 ', key: 'type' },
])
const items = ref([
  {
    favorites:true,
    title: '모니터링 A',
    subjectNum: '23',
    period: '24.01.25 ~ 25.01.25',
    type: '진행중',
  },
  {
    favorites:true,
    title: '협력사_에스피엠텍(B)',
    subjectNum: '23',
    period: '24.01.25 ~ 25.01.25',
    type: '진행중',
  }
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
</script>


<style lang="scss" scoped>
</style>
