<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="94rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">모니터링형식 설정</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text class="ty01">
          <div class="form-group col-ty">
            <!--form-row-->
            <div class="form-row ty05">
              <div class="txt-tit ty04">타입</div>
              <div class="row">
                <div class="txt">타인모니터링</div>
              </div>
            </div>
            <!--form-row-->
            <div class="form-row ty05">
              <div class="txt-tit ty04">대상역할</div>
              <div class="row">
                <Select v-bind="select01"/></div>
                <c-btnText01 :btnText="'피관찰자팝업 퍼블(개발후삭제)'" style="margin-left:2rem" @click="DialogSafetyDetailSetTy101Model=true"/>
            </div>
            <div class="form-row ty05">
              <div class="txt-tit ty04">평가자</div>
              <div class="row">
                <c-btn01 :btnText="'변경'" class="sm" @click="DialogAppraiserListModel=true"/>
                <v-btn class="txt03">3명</v-btn>
              </div>
            </div>
          </div>
          <div class="sort-group ty02">
            <div class="sort-row">
              <div class="wl">
                <div class="txt04">총 3개</div>
              </div>
              <div class="wr">
                <c-btn02 :btnText="'불러오기'" class="sm" @click="DialogCallActionModel=true"/>
                <c-btn01 :btnText="'추가'" class="sm"/>
              </div>
            </div>
          </div>
          <div class="sortable-group02">
            <div class="sortable-header">
              <div class="col">노출<br>순서</div>
              <div class="col">제목</div>
              <div class="col">타입</div>
              <div class="col">삭제</div>
            </div>
            <Sortable
              :list="testItems"
              item-key="id"
              tag="div"
              class="sortable-cont"
            >
              <template #item="{element, index}">
                <div class="draggable" :key="element.title">
                  <div class="row">
                    <div class="col" aria-hidden="true">
                      <img src="../../assets/img/format-align-justify.svg" alt="">
                    </div>
                    <div class="col">
                      {{ element.title }}
                    </div>
                    <div class="col">
                      {{ element.type }}
                    </div>
                    <div class="col">
                      <BtnIcon class="btn-ico-delete"/>
                    </div>
                  </div>
                </div>
              </template>
            </Sortable>
          </div>

        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 btnText="취소"/>
          <c-btn01 btnText="저장"/>
        </v-card-actions>
      </v-card>
      <!--popup: 평가자 리스트-->
      <DialogAppraiserList v-model="DialogAppraiserListModel"
                                @close="DialogAppraiserListModel=false"
      />
      <!--popup: 행동불러오기-->
      <DialogCallAction v-model="DialogCallActionModel"
                        @close="DialogCallActionModel=false"
                        :pop-title="'행동불러오기'"
      />
      <!--popup: 피관찰자(타인모니터링) 형식-->
      <DialogSafetyDetailSetTy1-1 v-model="DialogSafetyDetailSetTy101Model"
                        @close="DialogSafetyDetailSetTy101Model=false"
      />
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
const dialog = defineModel()
const DialogAppraiserListModel = ref(false)
const DialogCallActionModel = ref(false)
const DialogSafetyDetailSetTy101Model = ref(false)
const testItems = [
  {
    title: '작업 전 안전체조 및 TBM 활동을 통한 위험예지훈작업 전 안전체조 및 TBM 활동을 통한 위험예지훈',
    type: '선택형'
  },
  {
    title:'작업 전 안전체조 및 TBM 활동을 통한 위험예지훈작업 전 안전체조 및 TBM 활동을 통한 위험예지훈작업 전 안전체조 및 TBM 활동을 통한 위험예지훈작업 전 안전체조 및 TBM 활',
    type: '선택형'
  },
]
const select01 = {
  select:  '피관찰자',
  items: ['피관찰자', 'test', 'test']
}
</script>


<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left;width:70%;}
    .col:nth-child(3){flex-grow:0;width:15%;}
    .col:nth-child(4){flex-grow:0;width:10%;}
  }
  .sortable-header{
    .col:nth-child(2){align-items:flex-start;}
  }
}
</style>
