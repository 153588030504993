import router from "../router";

let baseUrl = 'http://localhost:8080/upload/'
if(import.meta.env.MODE === 'local')
  baseUrl = 'http://localhost:8080/upload/'
else if(import.meta.env.MODE === 'test')
  baseUrl = 'http://localhost:8080/upload/'
else if(import.meta.env.MODE === 'prod')
  baseUrl = 'https://api.impactus2.co.kr/upload/'

const utils = {
  movePage(path) {
    router.push({ path: path })
  },
  movePageQuery(path, query) {
    router.push({ path: path, query:query })
  },
  log(log){
    if(import.meta.env.MODE != 'production')
      console.log(log)
  },
  isEmpty(text){
    if(!text || text==='undefined' || text==='null' || text===undefined || text===null)
      return true
    else
      return false
  },
  getBaseUrl(category, filePath){
    return baseUrl+category+'/'+filePath
  }
};

export default utils;
