<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="94rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">퀴즈형식 설정</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <div class="pop-cont">
          <div class="form-group col-ty sty1">
            <!--form-row-->
            <div class="form-row ty06">
              <div class="txt-tit ty04">타입</div>
              <div class="row">
                <div class="txt">퀴즈형식</div>
                <div class="txt">총 3개</div>
              </div>
            </div>
          </div>
        </div>
        <v-card-text>
          <div class="form-box"
               v-for="n in 5"
               :key="n">
            <div class="form-group col-ty">
              <BtnIcon class="btn-ico-delete02"></BtnIcon>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit ty05">제목<span class="imp">*</span></div>
                <div class="row">
                  <c-Input :placeholder="'제목을 입력하세요'" class="size04"/>
                </div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit ty05">보기갯수</div>
                <div class="row">
                  <Select v-bind="select01"/>
                  <div class="row-dv">
                    <c-Input :placeholder="'보기를 입력하세요'" class="size04"/>
                    <div class="col-dv">
                      <div class="check-group">
                        <v-checkbox v-model="checkbox1" label="정답" ></v-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="row-dv">
                    <c-Input :placeholder="'보기를 입력하세요'" class="size04"/>
                    <div class="col-dv">
                      <div class="check-group">
                        <v-checkbox v-model="checkbox2" label="정답" ></v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <div class="pop-cont03">
          <c-btnLine01 :btnText="'신규추가'" class="ty02"/>
        </div>
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 btnText="취소"/>
          <c-btn01 btnText="저장"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
const dialog = defineModel()
const checkbox1 = ref(true)
const checkbox2 = ref(false)
const select01 = {
  select:  '2',
  items: ['2', '3', '4']
}
</script>


<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left}
    .col:nth-child(3){flex-grow:0;width:6%;}
    .col:nth-child(4){flex-grow:0;width:6%;}
    .col:nth-child(5){flex-grow:0;width:12%;}
    .col:nth-child(6){flex-grow:0;width:12%;}
    .col:nth-child(7){flex-grow:0;width:8%;}
    .col:nth-child(8){flex-grow:0;width:6%;}
    .col:nth-child(9){flex-grow:0;width:6%;}
    .col:nth-child(10){flex-grow:0;width:6%;}
    .col:nth-child(11){flex-grow:0;width:5%;}
    .col:nth-child(12){flex-grow:0;width:5%;}
  }
  .sortable-footer{
    .col:nth-child(1){flex-grow:0;width:16%;}
    .col:nth-child(2){flex-grow:0;width:38%;}
  }
}
</style>
