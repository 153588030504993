<template>
  <div class="tit-group02 inner-ty">
    <h2 class="tit">Total: {{ totalCnt }}</h2>
    <div class="filter-btn-group">
      <v-btn class="reset" :class="filterDivClass">필터초기화</v-btn>
      <v-btn class="filter" @click="filterOpen" :class="filterDivClass">필터</v-btn>
    </div>
  </div>
  <div class="sort-group ty01" :class="filterDivClass">
    <div class="sort-row">
      <div class="wl">
        <div class="txt-total">Total: {{ totalCnt }}</div>
        <c-inputSearch v-bind="inputSearch" v-model="searchModel" @search-word="onClickSearch" @keyup.enter="onClickSearch"/>
      </div>
    </div>
    <div class="sort-row">
      <div class="wl">
        <div class="txt01">기간</div>
        <div class="date-sort">
          <c-InputDate v-model="startDate" :enableTimePicker="false" readonly="" />
          <div class="txt02">~</div>
          <c-InputDate v-model="endDate" :enableTimePicker="false" readonly=""/>
        </div>
        <div class="date-sort">
          <c-btn02 :btnText="'일주'" class="ty01" @click="onClickDate('week')"/>
          <c-btn02 :btnText="'한달'" class="ty01" @click="onClickDate('month')"/>
        </div>
        <Select v-bind="deptSelect" v-model="deptModel" v-if="monitoringType != 1"/>
        <Select v-bind="memberSelect" v-model="memberModel" v-if="monitoringType != 1"/>
      </div>
    </div>
  </div>
  <div class="tbl-group size-custom4">
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page-options="footerOptions"
      return-object
    >
      <template v-slot:item.author="{ item }" >
        <div class="ell01">
          {{item.author}}
        </div>
        <div class="txt-tag">
          {{item.authorDept}}
        </div>
      </template>
      <template v-slot:item.moData="{ item }" >
        <!--s:모바일용 데이터-->
        <div class="td-mobile">
          {{item.monitoringTitle}}
        </div>
        <div class="td-mobile n2">
          {{item.author}}
          <span>
                    {{item.authorDept}}
                    </span>
        </div>
        <div class="td-mobile n3">
          {{item.regDateFormat}}
        </div>
        <!--e:모바일용 데이터-->
      </template>
      <template v-slot:item.detailView="{ item }">
        <div class="flex-c">
          <BtnIcon class="btn-ico-search" @click="onClickDetail(item.idx)"/>
        </div>
      </template>
    </v-data-table>
  </div>
  <DialogMonitoringDetail
    v-model="monitoringDetailModel"
    @close="monitoringDetailModel=false"/>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import BbsService from "@/services/bbs.service";
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import dayjs from "dayjs";

const props = defineProps(['monitoringType'])

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
const snackbar = ref(false)
const snackText = ref('')
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const monitoringDetailModel = ref(false)
const tab = ref('tabCont1')
const filterDivClass = ref('off')
const items = ref([])
const searchModel = ref(null)
const totalCnt = ref(0)
const startDate = ref('')
const endDate = ref('')
const deptModel = ref({name:'부서', value:''})
const memberModel = ref({name:'개인', value:''})
const monitoringType = props.monitoringType

//ui
const inputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요. (,로 다중검색)',
}
const deptSelect = ref({items: [{name:'부서', value:''}]})
const memberSelect = ref({items: [{name:'개인', value:''}]})
const headers = [
  { title: '순번', key: 'idx' },
  { title: '모니터링명', key: 'monitoringTitle' },
  { title: '작성자', key: 'memberName' },
  { title: '모니터링여부', key: 'monitoringYn'},
  { title: '안전대화여부 ', key: 'timelineYn'},
  { title: '작성일시', key: 'regDateFormat' },
  { title: '모니터링 / 작성자 / 작성일시', key: 'moData' },
  { title: '상세보기', key: 'detailView' },
]
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//watch
watch(startDate, (newObj) => {
  onClickSearch()
})

watch(endDate, (newObj) => {
  onClickSearch()
})

watch(deptModel, (newObj) => {
  onClickSearch()
})

watch(memberModel, (newObj) => {
  onClickSearch()
})

watch(tab, (newObj) => {
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: '1',
        monitoringTitle: '모니터링1',
        memberName: '김동임',
        monitoringYn: 'Y',
        timelineYn: 'N',
        regDateFormat: '2024-01-25 13:28:40',
        moData: '생산1팀/과장',
      },
    ]
  }
  else {
    if(viewIdx) {
      //조직정보
      const user = JSON.parse(localStorage.getItem('user'))
      let params = {idx: 0, companyIdx: user.companyIdx, treeType: 'dept'}
      CompanyService.getCompanyTreeList(params).then(
        (response) => {
          _.forEach(response.result, function (item){
            deptSelect.value.items.push({name:item.treeName, value:item.idx})
          })
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
      //회원정보
      let params2 = {companyIdx: user.companyIdx, deptIdx: user.deptIdx}
      MemberService.getMemberListFilter(params2).then(
        (response) => {
          _.forEach(response.result, function (item){
            memberSelect.value.items.push({name:item.memberName, value:item.idx})
          })
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )

      onClickSearch()
    }
  }
})

//function
function filterOpen(){
  if(filterDivClass.value == 'off')
    filterDivClass.value = 'on'
  else
    filterDivClass.value = 'off'
}

function onClickDetail(idx){
  storeRef.popupIdx.value = idx
  storeRef.viewIdx.value = viewIdx
  monitoringDetailModel.value=true
}

function onClickSearch(){
  const user = JSON.parse(localStorage.getItem('user'))
  let params = {
    keyword: searchModel.value,
    startDate:startDate.value,
    endDate:endDate.value,
    companyIdx:user.companyIdx,
    memberIdx:memberModel.value['value'],
    idx:viewIdx,
    deptIdx:deptModel.value['value'],
    monitoringType:props.monitoringType //모니터링 타입있으면 member,dept필터 무시하고 서버에서 재필터링
  }

  BbsService.getAnswerList(params).then(
    (response) => {
      items.value = response.result
      totalCnt.value = response.result.length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  if(type == 'month')
    start = dayjs().subtract(1, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}
</script>


<style lang="scss" scoped>
.container.main.on{
  .v-window{}
}
</style>

