<template>
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">안전리더십 관리 상세</h2>
    </div>
    <div class="content-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">제목<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'제목을 입력하세요'" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">오픈일<span class="imp">*</span></div>
          <div class="row">
            <c-InputDate  :enableTimePicker="false" readonly="" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty02">
          <div class="txt-tit">중복 관찰 허용</div>
          <div class="row">
            <v-switch :model-value="true" inset hide-details/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">참여대상자</div>
          <div class="row">
            <c-btn01 :btnText="'추가'"/>
            <v-btn class="txt03">3명</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty02">
          <div class="txt-tit">보고서열람대상</div>
          <div class="row">
            <c-btn01 :btnText="'추가'"/>
            <v-btn class="txt03">3명</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">변경정보</div>
          <div class="row">
            <v-btn class="txt03" @click="DialogChangeHistoryModel=true">5번</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box ty01">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt04">안전리더십 과제 설정</div>
          </div>
          <div class="wr">
            <c-btn01 :btnText="'단계추가'"/>
          </div>
        </div>
      </div>
      <div class="sortable-group02">
        <div class="sortable-header">
          <div class="col">노출<br>순서</div>
          <div class="col">단계</div>
          <div class="col">제목</div>
          <div class="col">집중<br>관리</div>
          <div class="col">
            평가<br>가중치<br>
            <span>총20</span>
          </div>
          <div class="col">상세<br>설정</div>
          <div class="col">삭제</div>
        </div>
        <Sortable
        :list="testItems"
        item-key="id"
        tag="div"
        class="sortable-cont"
      >
        <template #item="{element, index}">
          <div class="draggable" :key="element.title">
            <div class="row">
              <div class="col" aria-hidden="true">
                <img src="@/assets/img/format-align-justify.svg" alt="">
              </div>
              <div class="col">
                {{ element.step }}
              </div>
              <div class="col">
                <div class="form-group">
                  <c-Input :placeholder="'단계명'"/>
                  <c-Input :placeholder="'상세설명'"/>
                </div>
              </div>
              <div class="col">
                <div class="check-group">
                  <v-checkbox></v-checkbox>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <c-Input :placeholder="''" class="size06"/>
                </div>
              </div>
              <div class="col">
                <BtnIcon class="btn-ico-set" @click="DialogSafetyDetailSetModel=true"/>
              </div>
              <div class="col">
                <BtnIcon class="btn-ico-delete"/>
              </div>
            </div>
          </div>
        </template>
      </Sortable>
        <div class="sortable-footer">
          <div class="col">총합</div>
          <div class="col">총 <span>30</span></div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
      </div>
      <div class="wr">
        <c-btn02 :btnText="'미리보기'" @click="DialogPreviewModel=true"/>
        <c-btn01 :btnText="'저장'"/>
      </div>
    </div>
  </div>
  <!--popup: 변경이력-->
  <c-dialog v-model="DialogChangeHistoryModel"
            v-bind="DialogChangeHistoryBind"
            @close="DialogChangeHistoryModel=false"
  />
  <!--popup: 상세설정-->
  <DialogSafetyDetailSet v-model="DialogSafetyDetailSetModel"
            @close="DialogSafetyDetailSetModel=false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Sortable } from "sortablejs-vue3";

const DialogChangeHistoryModel = ref(false)
const DialogPreviewModel = ref(false)
const DialogSafetyDetailSetModel = ref(false)

const testItems = [
  {
    step: '1',
  },
  {
    step: '2',
  },
]
const DialogChangeHistoryBind = {
  dialogTit: '변경 이력',
  dialogText: '생성 / admin / 2024.01.03 수정(제목) / admin / 2024.01.04<br>대상추가(3명) / admin / 2024.01.04<br>대상제거(1명) / admin / 2024.01.04<br>행동수정 / admin / 2024.01.04',
  btn1Text: '확인',
  btn1Show: true,
}
</script>



<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){flex-grow:0;width:5%;}
    .col:nth-child(3){justify-content:flex-start;text-align:left}
    .col:nth-child(4){flex-grow:0;width:5%;}
    .col:nth-child(5){flex-grow:0;width:8%;}
    .col:nth-child(6){flex-grow:0;width:5%;}
    .col:nth-child(7){flex-grow:0;width:5%;}
  }
  .sortable-footer{
    .col:nth-child(1){flex-grow:0;width:16%;}
    .col:nth-child(2){flex-grow:0;width:27%;}
  }
}

</style>
