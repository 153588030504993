<template>
  <div class="tabCont">
    <div class="p-inner ty01">
      <div class="tit-group02">
        <h2 class="tit">{{sendTitle}}</h2>
      </div>
      <div class="content-box">
        <div class="tit-group02 inner-ty">
          <h2 class="tit">Total: {{totalCnt}}</h2>
          <div class="filter-btn-group">
            <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
            <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
          </div>
        </div>
        <div class="sort-group" :class="filterDiv1">
          <div class="sort-row">
            <div class="wl">
              <div class="txt-total">Total: {{ totalCnt }}</div>
              <c-inputSearch v-bind="inputSearch" v-model="searchModel" @search-word="onClickSearch" @keyup.enter="onClickSearch"/>
              <div class="date-sort">
                <c-InputDate :enableTimePicker="false" readonly="" v-model="startDate"/>
                <div class="txt02">~</div>
                <c-InputDate :enableTimePicker="false" readonly="" v-model="endDate"/>
              </div>
              <div class="date-sort">
                <c-btn02 :btnText="'일주'" class="ty01" @click="onClickDate('week')"/>
                <c-btn02 :btnText="'한달'" class="ty01" @click="onClickDate('month')"/>
              </div>
              <Select v-bind="statusSelect" v-model="status"/>
            </div>
          </div>
        </div>
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page-options="footerOptions"
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="utils.movePage('/myBoardDetail/'+item.idx)">
                <td>{{item.idx}}</td>
                <td>
                  <div class="ell01">
                    {{item.situation}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.goalDate)">
                    {{item.goalDate}}
                  </div>
                </td>
                <td>{{item.memberName}}</td>
                <td>{{item.targetText}}</td>

                <td>
                  <div :class="getColor2(item.status)">
                    {{item.statusText}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.status)">
                      {{item.statusText}}
                    </div>
                    <div class="ell01">
                      {{item.situation}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.goalDate)">
                      {{item.goalDate}}
                    </div>
                    &nbsp;/ {{item.memberName}} / {{item.targetText}}
                  </div>
                </td>
                <td>{{item.modDateFormat}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'" @click.stop="onClickGoList()"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useCommonStore } from "@/store/common";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import dayjs from "dayjs";

//1:나의시정 2:부서시정 3:나의칭찬 4:부서칭찬
const props = defineProps(['tabType'])
//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const items:any = ref([])
const searchModel = ref(null)
const totalCnt = ref(0)
const startDate = ref('')
const endDate = ref('')
const status = ref({name:'전체', value:''})
const sendTitle = ref('')

//ui
const inputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요.',
}
const statusSelect = {items: [{name:'전체', value:''},{name:'진행중', value:'2'},{name:'보류', value:'3'},{name:'완료', value:'4'}]}
const headers = ref([
  { title: '순번', key: 'idx' },
  { title: '시정조치', key: 'situation' },
  { title: '조치기한', key: 'goalDate'},
  { title: '작성자 ', key: 'memberName' },
  { title: '대상자', key: 'targetText' },
  { title: '진행상황', key: 'statusText' },
  { title: '시정조치 / 조치기한 / 작성자 / 대상자', key: 'moDate' },
  { title: '등록일', key: 'modDateFormat' },
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//watch
watch(startDate, (newObj) => {
  onClickSearch()
})

watch(endDate, (newObj) => {
  onClickSearch()
})

watch(status, (newObj) => {
  onClickSearch()
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: '1',
        situation: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
        goalDate: '2024-01-25',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '진행중',
        modDateFormat: '2024-01-25'
      },
      {
        idx: '2',
        situation: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
        goalDate: '2024-01-23',
        memberName: '김명기',
        targetText: '마케팅팀',
        statusText: '보류',
        modDateFormat: '2024-01-25'
      },
    ]
  }
  else {
    onClickSearch()
  }
})

//function
function onClickSearch(){
  if(storeRef.senderType.value == 'send')
    sendTitle.value = '발행 시정조치'
  else if(storeRef.senderType.value == 'receive')
    sendTitle.value = '수신 시정조치'
  const tab = storeRef.tabName.value
  const curTab = props.tabType
  if(tab != curTab)
    return

  const user = JSON.parse(localStorage.getItem('user'))
  let memberIdx = user.memberIdx
  let deptIdx = user.deptIdx
  //1,3은 개인 2,4는 부서
  if(props.tabType == 'tabCont1' || props.tabType == 'tabCont3')
    deptIdx = 0
  else
    memberIdx = 0
  let params = {
    memberIdx:memberIdx,
    deptIdx:deptIdx,
    keyword:searchModel.value,
    startDate: startDate.value,
    endDate: endDate.value,
    status: status.value['value'],
  }
  TimelineService.getTimelineSummary(params).then(
    (response) => {
      //send, receive 선택
      if(storeRef.senderType.value == 'send')
        items.value = response.result.sendTimelines
      else
        items.value = response.result.receiveTimelines
      //안전대화, 지지적 분리
      items.value = _.filter(items.value, {type:(props.tabType == 'tabCont1' || props.tabType == 'tabCont2')?'1':'2'})
      //상세데이터 변경
      _.forEach(items.value, function(val, key) {
        val.actionYnText = val.actionYn=='Y'?'필요':'불필요'
        val.targetText = '없음'
        if(val.targetType == '2') val.targetText = val.targetDeptName
        else if(val.targetType == '3') val.targetText = val.targetMemberName
        val.goalDate = dayjs(val.goalDate).format('YYYY-MM-DD') == 'Invalid Date' ? '':dayjs(val.goalDate).format('YYYY-MM-DD')
        val.statusText = '준비'
        if(val.status == '2')
          val.statusText = '진행중'
        else if(val.status == '3')
          val.statusText = '보류'
        else if(val.status == '4')
          val.statusText = '완료'
      })
      totalCnt.value = items.value.length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 403)
        utils.movePage('/login')
    }
  )
}
function getColor(goalDate) {
  const startDate = dayjs();
  const endDate = dayjs(goalDate);
  const duration = endDate.diff(startDate, 'days');
  if (duration < 7)
    return 'txt-red'
}
function getColor2(status) {
  if (status == '2') return 'txt-blue'
  else if (status == '3') return 'txt-red'
}
function onClickGoList(){
  //store.moveTab(props.tabType)  tabview 이름이 여러개일때만 사용가능
  storeRef.isTabView1.value = false
}
function onClickDate(type){
  const end = dayjs().format('YYYY-MM-DD')
  let start = dayjs().format('YYYY-MM-DD')
  if(type == 'week')
    start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
  else if(type == 'month')
    start = dayjs().subtract(4, 'month').format('YYYY-MM-DD')

  startDate.value = start.toString()
  endDate.value = end.toString()
}
//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}
</script>


<style lang="scss" >
</style>
