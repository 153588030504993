import api from "./api";
import Utils from "@/common/Utils";

class OrganizationService {
  getClient(params) {
    return api
      .post("/organization/getClient", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getClientList(params) {
    return api
      .post("/organization/getClientList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertUpdateClient(params) {
    let apiUrl = '/organization/insertClient'
    if(params['idx'])
      apiUrl = '/organization/updateClient'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getCompanyAdm(params) {
    return api
      .post("/organization/getCompanyAdm", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getCompanyListAdm(params) {
    return api
      .post("/organization/getCompanyListAdm", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertUpdateCompanyAdm(params) {
    let apiUrl = '/organization/insertCompanyAdm'
    if(params['idx'])
      apiUrl = '/organization/updateCompanyAdm'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new OrganizationService();
