<template>
  <div class="img-add-group">
    <div class="btn-img-upload">
      <v-file-input
        label="File input w/ chips"
        chips
        multiple
        accept="image/png, image/jpeg"
        @change="onChangeImg"
      >추가</v-file-input>
    </div>
    <div class="img-add-group">
      <div class="item" v-for="(item, i) in imgItems" :key="i">
        <v-btn @click="onClickPreview(item)">
          <img :src="item.src">
        </v-btn>
        <v-btn class="btn-img-del" @click="onClickDelete(item)"/>
      </div>
    </div>
  </div>
  <DialogImgView v-model="isShowDialog" @close="isShowDialog=false" :src="previewSrc"/>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import TimelineService from "@/services/timeline.service";
import utils from "@/common/Utils";
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import _ from 'lodash';

const props = defineProps(['seq'])

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)
const route:any = useRoute()

//bind
let viewIdx = route.params.idx?route.params.idx:storeRef.viewIdx.value
const isShowDialog = ref(false)
const imgItems = ref([])
const previewSrc = ref('')

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    imgItems.value = [
      {
        idx:1,
        src:'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      },
      {
        idx:2,
        src:'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
      }
    ]
  }
  else {
    let params = {
      monitoringIdx: viewIdx,
      isTemp:'Y',
      tempSeq: props.seq
    }

    TimelineService.getTimelineImageList(params).then(
      (response) => {
        if(response.result.length > 0){
          _.forEach(response.result, function(val, key) {
            const tempObj = {idx:val.idx, src:utils.getBaseUrl('timeline', val.filePath)}
            imgItems.value.push(tempObj)
          })
        }
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
function onChangeImg(e: Event){
    const [file]:any = (e.target as HTMLInputElement).files;
    if (file) {
      const tempObj = {idx:0, src:URL.createObjectURL(file)}

      const user = JSON.parse(localStorage.getItem('user'))
      const formData = new FormData();
      formData.append("file", file);
      formData.append("monitoringIdx", viewIdx);
      formData.append("isTemp", "Y");
      formData.append("tempSeq", props.seq);

      TimelineService.insertTimelineImage(formData).then(
        (response) => {
          imgItems.value.push(tempObj)
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 403)
            utils.movePage('/login')
        }
      )
    }
}

function onClickPreview(item){
  previewSrc.value = item.src
  isShowDialog.value = true
}

function onClickDelete(item){
  imgItems.value = _.pull(imgItems.value, item)
}

</script>


<style lang="scss">
.img-add-group{
  .btn-img-upload{
    .v-input{
      display:block;
      .v-input__prepend{
        margin:0;
        .v-icon{
          display:block;
          width:auto;
          padding:0 2rem;
          height:4rem;
          line-height:4rem;
          text-align:center;
          color:var(--color-white);
          background: var(--color-main);
          border-radius:.5rem;
          opacity:1;
          &:before{
            content:"추가";
            font-size:1.5rem;
          }
        }
      }
      .v-field{
        .v-field__overlay,
        .v-field__loader,
        .v-label,
        .v-field__outline{
          display:none;
        }
        .v-chip{
          font-size:1.2rem
        }
      }
      .v-input__details{
        display:none;
      }
      .v-field__input{
        padding:.8rem 0;
        min-height:auto;
      }
      .v-input__control{
        //display: inline-block;
        display:none;
      }
      button.v-btn{
        display:block;
        width:5rem;
        height:5rem;
        line-height:5rem;
        text-align:center;
        color:var(--color-white);
        background: var(--color-gray4);
      }
    }
  }
  .img-add-group{
    display:flex;
    flex-wrap: wrap;
    .item{
      position:relative;
      width:12rem;
      height:8rem;
      overflow:hidden;
      margin-right:1rem;
      margin-top:1rem;
      background:#DEE2EA url("/src/assets/img/ico-img.svg") center no-repeat;
      *{
        object-fit: contain;
        width:12rem;
        height:8rem;
      }
      .v-btn{
        background:transparent;
        &.btn-img-del{
          display:block;
          position:absolute;
          top:0;
          right:0;
          width:2.4rem;
          height:2.4rem;
          min-width:auto;
          padding:0;
          box-shadow:none;
          background: url("/src/assets/img/ico-img-close.svg") center no-repeat;
          *{
            width:2.4rem;
            height:2.4rem;
          }
        }
      }
    }
  }
}
</style>
