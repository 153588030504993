<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="55rem"
      class="layer-popup"
      scrollable
    >
      <Form @submit.prevent="handleForm" :validation-schema="schema">
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">그룹정보</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="form-group row-ty">
            <div class="form-row">
              <div class="txt-tit">
                그룹명<span class="imp">*</span>
              </div>
              <div class="row">
                <c-Input :placeholder="'그룹명을 입력하세요'" v-model="companyName" :name="'companyName'"/>
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                그룹 관리자ID
                <c-tooltip v-bind="managerTooltip"/>
              </div>
              <div class="row">
                <c-InputIdAdd :isShowLoading="true"/>
              </div>

            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 v-bind="btnDel" @click.prevent="dialogModel=true"/>
          <c-btn01 v-bind="btnSave"/>
        </v-card-actions>
      </v-card>
      </Form>
      <!--삭제-->
      <c-dialog v-model="dialogModel"
                v-bind="dialogBind"
                @close="dialogModel=false"
                @confirm="confirmDelete"
      />
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import * as yup from "yup";
import OrganizationService from "@/services/organization.service";
import CompanyService from "@/services/company.service";
import utils from "@/common/Utils";
import router from "@/router";

const emit = defineEmits(['close'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//validation schema
const schema = yup.object().shape({
  companyName: yup.string().required("필수 항목입니다."),
});

//bind
const form = ref(null)
const companyName = ref('')
const dialogModel = ref(false)

//ui
const dialogBind = {
  dialogTit: '삭제',
  dialogText: '선택하신 회사를 삭제하시겠습니까? ',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const managerTooltip = {
  TooltipText: '관리자는 해당그룹에 신규 유저로 생성됩니다.'
}
const btnDel = {
  btnText: '삭제',
  btnType: 'reset'
}
const btnSave = {
  btnText: '저장',
  btnType: 'submit'
}

//hook
onBeforeUpdate (()=>{
  companyName.value = ''
  storeRef.adminList.value = []
})

onUpdated (() => {
  if(!dialog.value)
    return;

  let params = {
    idx: storeRef.popupIdx.value,
  }

  if(params.idx){
    OrganizationService.getCompanyAdm(params).then(
      (response) => {
        companyName.value = response.result.companyName
        storeRef.adminList.value = response.result.adminList
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if(error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let params = {
      idx: storeRef.popupIdx.value,
      clientIdx: localStorage.getItem('clientIdx'),
      companyName: companyName.value,
      adminList: Array.from(storeRef.adminList.value)
    }

    OrganizationService.insertUpdateCompanyAdm(params).then(
      (response) => {
        emit('close', 'refresh')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function confirmDelete(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let companyIdx = storeRef.popupIdx.value
    if(utils.isEmpty(companyIdx))
      utils.movePage('/login')
    let params = {
      idx: companyIdx,
      delYn: 'Y',
    }

    OrganizationService.insertUpdateCompanyAdm(params).then(
      (response) => {
        emit('close', 'refresh')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
</script>


<style lang="scss">
</style>
