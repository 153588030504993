<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="94rem"
      class="layer-popup sl-pop1"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">1.안전리더십 소개</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <div class="pop-cont">
          <div class="tab-group">
            <v-tabs
              v-model="tab"
            >
              <v-tab value="tabCont1">1-1 2024년도 조직별 안전보건 전략 수립</v-tab>
              <v-tab value="tabCont2">1-2 셀프모니터링</v-tab>
              <v-tab value="tabCont3">1-3 타인모니터링</v-tab>
            </v-tabs>
          </div>
        </div>
        <v-card-text>
          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="tabCont1">
              <DialogSLR101/>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont2">
              <DialogSLR102/>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont3">
              <DialogSLR103/>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 btnText="제출"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Sortable } from 'sortablejs-vue3';
const dialog = defineModel()
import { useRoute } from 'vue-router'
import { useDialogSLR1Store } from "@/store/dialogSLR1";
import { storeToRefs } from 'pinia'
import DialogSLR101 from '@/components/safety/DialogSLR101.vue';
const route = useRoute()
const store = useDialogSLR1Store()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
const isTabView = storeRef.isTabView

// watch는 ref에서 직접 작동합니다
watch(tab, async (newTab, oldTab) => {
  storeRef.isTabView.value = false
})
</script>


<style lang="scss" scoped>
</style>
