<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="57rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">대상 추가</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text>
          <div class="form-group col-ty">
            <!--form-row-->
            <div class="form-row">
              <div class="row">
                <div class="col">
                  <c-Input v-model="memberId" v-bind="InputName" class="size02" @keyup.enter="getData"/>
                </div>
                <div class="col">
                  <Select v-model="posIdx" v-bind="selectPos"/>
                </div>
                <div class="col">
                  <Select v-model="deptIdx" v-bind="selectDept"/>
                </div>
                <div class="col">
                  <Select v-model="shiftYn" v-bind="selectShift"/>
                </div>
                <div class="col">
                  <Select v-model="headYn" v-bind="selectHead"/>
                </div>
              </div>
            </div>
          </div>
          <div class="selected-group scrollbar">
            <v-item-group selected-class="bg-active ">
              <v-container>
                <div class="list-group">
                  <div class="list-row"
                       v-for="(item, i) in members"
                       :key="i"
                  >
                    <div class="list-txt">
                      <v-item>
                        <v-card
                          :class="item.selected?'bg-active':''"
                          dark
                          @click="handleForm(item)"
                        >
                          <div class="txt">
                            {{item.memberInfo}}
                          </div>
                        </v-card>
                      </v-item>
                    </div>
                  </div>
                </div>
              </v-container>
            </v-item-group>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 v-bind="btnSave" @click="handleForm"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref} from 'vue'
import CompanyService from "@/services/company.service";
import MemberService from "@/services/member.service";
import _ from 'lodash';
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import utils from "@/common/Utils";

const emit = defineEmits(['close', 'confirm'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const memberId = ref('')
const posIdx = ref({name: '직책', value: ''})
const deptIdx = ref({name: '부서', value: ''})
const shiftYn = ref({name: '교대조', value: ''})
const targetYn = ref({name: '대상', value: ''})
const headYn = ref({name: '조직장', value: ''})
const memberCnt = ref(0)
const members = ref([])
const targetCnt = ref(0)
const selectDept = ref({items: [{name: '부서', value: ''}]})
const selectPos = ref({items: [{name: '직책', value: ''}]})

//ui
const btnSave = {
  btnText: '저장',
}
const InputName = {
  type: 'text',
  placeholder: '이름',
}
const selectTarget = {items: [{name: '대상', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectHead = {items: [{name: '조직장', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}
const selectShift = {items: [{name: '교대조', value: ''},{name: 'Y', value: 'Y'},{name: 'N', value: 'N'}]}

//hook
onBeforeUpdate (()=>{
  members.value = []
})

onUpdated(() => {
  if(!dialog.value)
    return;

  if(import.meta.env.MODE == 'development'){
    members.value = [
      {
        memberInfo: '현장소장 / 염소정제팀 / 관리감독자',
        idx: '1',
        selected:false
      },
      {
        memberInfo: '현장소장2 / 염소정제팀2 / 관리감독자2',
        idx: '2',
        selected:false
      },
    ]
  }
  else {
    getCompanyInfo()
  }
})

//watch
watch(posIdx, (newValue) => {
  getData()
})
watch(deptIdx, (newValue) => {
  getData()
})
watch(shiftYn, (newValue) => {
  getData()
})
watch(targetYn, (newValue) => {
  getData()
})
watch(headYn, (newValue) => {
  getData()
})

//function
function getCompanyInfo(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    idx: companyIdx,
  }

  CompanyService.getCompany(params).then(
    (response) => {
      if(response.result){
        let posArr = {items: [{name: '부서', value: ''}]}
        response.result.posList.forEach(function (item){
          posArr.items.push({name:item.treeName, value:item.idx})
        })
        selectPos.value = posArr

        let deptArr = {items: [{name: '직책', value: ''}]}
        response.result.deptList.forEach(function (item){
          deptArr.items.push({name:item.treeName, value:item.idx})
        })
        selectDept.value = deptArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function getData(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
    memberId: memberId.value,
    posIdx: posIdx.value['value'],
    deptIdx: deptIdx.value['value'],
    shiftYn: shiftYn.value['value'],
    targetYn: targetYn.value['value'],
    headYn: headYn.value['value'],
  }

  MemberService.getMemberListFilter(params).then(
    (response) => {
      if(response.result){
        let memberArr = []
        response.result.forEach(function (item){
          memberArr.push({idx:item.idx, memberInfo:item.memberId+' / '+item.posName+' / '+item.deptName, selected:false})
        })
        members.value = memberArr
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function handleForm(item){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    emit('confirm', item)
  }
}

</script>


<style lang="scss">
</style>
