<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="list-group2">
          <div class="list" v-for="(item, i) in monitoringItems" :key="i">
            <div class="row1">
              <div class="num">{{i+1}}</div>
              <div class="txt">{{item.behaviorTitle}}</div>
            </div>
            <div class="row2">
              <div class="radio-group2">
                <v-radio-group v-model="item.answerData">
                  <v-radio label="안전" value="1"></v-radio>
                  <v-radio label="불안전" value="0"></v-radio>
                </v-radio-group>
              </div>
              <div class="inner" v-if="item.answerData == 0">
                <div class="radio-group3">
                  <v-radio-group>
                    <v-radio v-for="(item2, i ) in item.behaviorComments" :label="item2.commentTitle" :value="item2.idx"></v-radio>
                  </v-radio-group>
                </div>
                <c-input :placeholder="''"></c-input>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave" @click.stop="dialog = false"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref } from 'vue'
import {useCommonStore} from "@/store/common";
import {storeToRefs} from "pinia";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import _ from 'lodash';
import dayjs from "dayjs";

const props = defineProps(['popTitle'])
const dialog = defineModel()

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const monitoringItems = ref([])

//ui
const btnSave = {
  btnText: '확인',
}

//hook
onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    monitoringItems.value = [
      {
        "idx": 1,
        "behaviorTitle": "1",
        "behaviorContent": "2",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "#tag,#tag2",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 20:29:52.156732",
        'answerData': '1',
        "duplicateYn": 'Y'
      },
      {
        "idx": 2,
        "behaviorTitle": "2",
        "behaviorContent": "22",
        "behaviorType": "2",
        "itemCount": 0,
        "multiYn": "N",
        "tag": "333",
        "delYn": "N",
        "essentialYn": "Y",
        "regDate": "2024-12-01 21:04:48.400642",
        'answerData': '1',
        "duplicateYn": 'Y'
      }
    ]
  }
  else {
    monitoringItems.value = storeRef.popupItems.value
  }
})
</script>


<style lang="scss">

</style>
