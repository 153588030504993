<template>
  <div class="mb-page-wrap">
    <div class="tit-group mo-none">
      <h2 class="tit">My Board</h2>
    </div>
    <div class="tab-group">
      <v-tabs
        v-model="tab"
      >
        <v-tab value="tabCont1">나의 시정조치</v-tab>
        <v-tab value="tabCont2">부서 시정조치</v-tab>
        <v-tab value="tabCont3">나의 칭찬</v-tab>
        <v-tab value="tabCont4">부서 칭찬</v-tab>
      </v-tabs>
    </div>
    <div class="content-box bg-none">
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="tabCont1">
          <MyBoardTabCont1 v-if="!tabView1" :tabType="'tabCont1'"/>
          <MyBoardTabCont11 v-if="tabView1" :tabType="'tabCont1'"/>
        </v-tabs-window-item>
        <v-tabs-window-item value="tabCont2">
          <MyBoardTabCont1 v-if="!tabView1" :tabType="'tabCont2'"/>
          <MyBoardTabCont11 v-if="tabView1" :tabType="'tabCont2'"/>
        </v-tabs-window-item>
        <v-tabs-window-item value="tabCont3">
          <MyBoardTabCont1 v-if="!tabView1" :tabType="'tabCont3'"/>
          <MyBoardTabCont11 v-if="tabView1" :tabType="'tabCont3'"/>
        </v-tabs-window-item>
        <v-tabs-window-item value="tabCont4">
          <MyBoardTabCont1 v-if="!tabView1" :tabType="'tabCont4'"/>
          <MyBoardTabCont11 v-if="tabView1" :tabType="'tabCont4'"/>
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCommonStore } from "@/store/common";
import { storeToRefs } from 'pinia'

//store
const route = useRoute()
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const tab = storeRef.tabName
const tabView1 = storeRef.isTabView1

//watch
watch(tab, (newDate) => {
  tabView1.value = false
});

store.moveTab('tabCont1')
</script>



<style lang="scss" scoped>
</style>
