<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: {{ totalCnt }}</div>
            <c-InputSearch :placeholder="'검색어를 입력하세요. (,로 다중검색)'"  v-model="inputSearch" @search-word="onClickSearch"/>
          </div>
          <div class="wr">
            <c-btn01 v-bind="btnNewUpload" @click="onClickViewPage4"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom3">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          @click:row="onClickViewPage4"
        >
        </v-data-table>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="3000"> {{ snackText }} </v-snackbar>
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import {useCommonStore} from "@/store/common";
import BoardService from "@/services/board.service";
import utils from "@/common/Utils";
import {storeToRefs} from "pinia";

//store
const store = useCommonStore()
const storeRef = storeToRefs(store)

//bind
const snackbar = ref(false)
const snackText = ref('')
const totalCnt = ref(0)
const inputSearch = ref(null)
const selected = ref([])

//ui
const btnListDel = {
  btnText: '선택삭제',
  btnShow: true
}
const btnNewUpload = {
  btnText: '신규등록',
}
const headers = ref([
  { title: '순번', key: 'idx' },
  { title: '타입', key: 'type' },
  { title: '회사', key: 'companyName'},
  { title: '작성자 ', key: 'memberId' },
  { title: '이메일발송여부 ', key: 'mailYn' },
  { title: '발송결과 ', key: 'mailSendYn' },
  { title: '발송시간', key: 'mailSendDate' },
  { title: '등록일 ', key: 'regDate' },

])
const items = ref([])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        type: '회사공지',
        companyName: '삼성',
        memberId: '어드민',
        mailYn: 'O',
        mailSendYn: 'X',
        mailSendDate: '2024-01-25 13:28:40',
        regDate: '2024-01-25 13:28:40',
      },
      {
        idx: 2,
        type: '타입',
        companyName: '회사이름이 들어갑니다.',
        memberId: '어드민1234',
        mailYn: 'O',
        mailSendYn: 'X',
        mailSendDate: '2024-01-25 13:28:40',
        regDate: '2024-01-25 13:28:40',
      },
    ]
    totalCnt.value = 2
  }
  else{
    onClickSearch('')
  }
})

//function
function onClickSearch(word){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    keyword: word,
    companyIdx:companyIdx
  }

  BoardService.getBoardList(params).then(
    (response) => {
      items.value = response.result
      totalCnt.value = response.result.length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickViewPage4(event, row){
  if(row)
    storeRef.viewIdx.value = row.item.idx
  else
    storeRef.viewIdx.value = null
  store.moveTabView('tabCont4')
}
</script>

